.m-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      border-bottom: 1px dashed $border-color;
    }
  }
}

.stars {
  &::before {
    content: "\f005\f005\f005\f005\f005 ";
    font-family: "fontawesome";
  }

  &-2::before {
    content: "\f005\f005";
  }

  &-2-5::before {
    content: "\f005\f005\f089";
  }

  &-3::before {
    content: "\f005\f005\f005";
  }

  &-3-5::before {
    content: "\f005\f005\f005\f089";
  }

  &-4::before {
    content: "\f005\f005\f005\f005";
  }

  &-4-5::before {
    content: "\f005\f005\f005\f005\f089";
  }

  &-5::before {
    content: "\f005\f005\f005\f005\f005";
  }
}

.tooltip .tooltip-inner {
  p {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// range input

input[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 3px solid $primary;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  margin-top: -8px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 3px solid $primary;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 3px solid $primary;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: $orange;
  cursor: pointer;
}

// track
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: $primary;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: $primary;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: $primary;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: $primary;
}
input[type="range"]:focus::-ms-fill-lower {
  background: $primary;
}
input[type="range"]::-ms-fill-upper {
  background: $primary;
}
input[type="range"]:focus::-ms-fill-upper {
  background: $primary;
}
