.course-first-section {
  margin-top: -75px;
  position: relative;
  z-index: 10;

  @media (max-width: 991px) {
    margin-top: 50px;
  }
}

.course-process {
  display: flex;
  border-right: 0;
  position: relative;
  z-index: 10;
  margin: 0 -15px 0;
  width: calc(100% + 30px);

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 150px;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;
    filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.3));

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      clip-path: polygon(
        0% 0%,
        calc(100% - 10px) 0%,
        calc(100% - 10px) calc(50% - 10px),
        100% 50%,
        calc(100% - 10px) calc(50% + 10px),
        calc(100% - 10px) 100%,
        0% 100%,
        0% calc(50% + 10px),
        10px 50%,
        0% calc(50% - 10px),
        0% 0%
      );
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      background-color: $white;
    }

    &:first-child {
      &::before {
        clip-path: polygon(
          10px 0%,
          calc(100% - 10px) 0%,
          calc(100% - 10px) calc(50% - 10px),
          100% 50%,
          calc(100% - 10px) calc(50% + 10px),
          calc(100% - 10px) 100%,
          10px 100%
        );
      }
    }

    &:last-child {
      &::before {
        background-color: $primary;
        clip-path: polygon(
          0% 0%,
          calc(100% - 10px) 0%,
          calc(100% - 10px) 100%,
          0% 100%,
          0% calc(50% + 10px),
          10px 50%,
          0% calc(50% - 10px),
          0% 0%
        );
      }
    }
  }

  &__title {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 700;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 78px;
      border-bottom: 2px solid $primary;
    }
  }

  &__time {
    font-size: 20px;
  }

  &__start {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $white;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 700;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  @media (max-width: 1199px) {
    &__item {
      height: 100px;
    }

    &__title {
      font-size: 14px;

      &::after {
        width: 50px;
      }
    }

    &__time {
      font-size: 12px;
    }

    &__start {
      font-size: 18px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin: 30px 0 0;
    width: 100%;
    height: auto;

    &__item {
      flex: unset;
      height: 100px;

      &::before {
        clip-path: polygon(
          0% 0,
          calc(50% - 8px) 0,
          50% 8px,
          calc(50% + 8px) 0,
          100% 0%,
          100% calc(100% - 8px),
          calc(50% + 8px) calc(100% - 8px),
          50% 100%,
          calc(50% - 8px) calc(100% - 8px),
          0% calc(100% - 8px),
          0% 100%
        );
      }

      &:first-child {
        &::before {
          clip-path: polygon(
            0% 0,
            100% 0%,
            100% calc(100% - 8px),
            calc(50% + 8px) calc(100% - 8px),
            50% 100%,
            calc(50% - 8px) calc(100% - 8px),
            0% calc(100% - 8px),
            0% 100%
          );
        }
      }

      &:last-child {
        &::before {
          clip-path: polygon(
            0% 0,
            calc(50% - 8px) 0,
            50% 8px,
            calc(50% + 8px) 0,
            100% 0%,
            100% 100%,
            0% 100%
          );
        }
      }
    }

    &__title {
      padding-bottom: 10px;
      margin-bottom: 8px;
    }
  }
}

.job-opportunities {
  @include clearfix;

  &__iwrap {
    display: block;
    position: relative;
    z-index: 1;
    padding: 40px 30px 30px 0;
    float: right;
    width: 600px;
    margin-left: 50px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 46px;
      z-index: -1;
      background-image: linear-gradient(
        to bottom right,
        transparent,
        transparent calc(50% - 1px),
        $secondary 50%,
        $secondary calc(50% + 1px),
        transparent calc(50% + 2px),
        transparent calc(50% + 20px),
        $secondary calc(50% + 21px),
        $secondary 100%
      );
    }

    img {
      display: block;
      width: 100%;
      @include shadow;
    }
  }

  h2 {
    font-size: 21px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 16px;

    span {
      display: inline-block;
      position: relative;
      padding-bottom: 8px;

      &::after {
        content: '';
        display: block;
        width: 86px;
        border-bottom: 1px solid #277ffd;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  @media (max-width: 991px) {
    &__iwrap {
      width: 100%;
      max-width: unset;
      margin-left: 0;
      margin-bottom: 20px;
      float: none;
    }
  }

  @media (max-width: 767px) {
    padding: 20px;
  }
}

.scholarship {
  @include shadow-md;
  @include clearfix;
  background-color: #e6e6e6;
  margin-bottom: 100px;
  padding: 30px;

  &__iwrap {
    float: left;
    padding: 34px 0 34px 76px;
    position: relative;
    z-index: 1;
    margin-right: 60px;
    max-width: 50%;
    margin: -30px 60px 30px -30px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 40px;
      background-color: $secondary;
    }

    img {
      display: block;
      width: 100%;
      @include shadow;
    }
  }

  @media (max-width: 991px) {
    &__iwrap {
      width: 100%;
      max-width: unset;
      margin-right: 0;
      margin-bottom: 20px;
      float: none;
    }
  }

  @media (max-width: 767px) {
    &__iwrap {
      padding: 30px 0 30px 30px;

      &::before {
        right: 30px;
      }
    }
  }
}

.course-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;

  span {
    display: inline-block;
    padding-bottom: 10px;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 85px;
      max-width: 100%;
      border-bottom: 2px solid $primary;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &--white {
    color: $white;

    span::after {
      border-color: $white;
    }
  }
}

.course-btn {
  display: block;
  text-align: center;
  border: 1px solid $primary;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 50px;
  padding: 0 20px;
  color: $primary;
  transition: 0.3s;

  &:hover {
    text-decoration: none;
    background-color: $primary;
    color: $white;
  }
}

.course-aside {
  background-color: $secondary;
  color: $white;
  border-radius: 4px;
  padding: 4px;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fec922;
    border-radius: 4px 4px 0 0;
    padding: 25px 10px;

    img {
      height: 62px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__body {
    padding: 20px;
  }

  &__alert {
    background-color: $primary;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    padding: 4px;
  }
}

.course-info-list {
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 12px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border: 1px solid $primary;
      background-color: $white;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

.course-condition {
  ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;

    li {
      padding-left: 30px;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &::before {
        content: '\f00c';
        font-family: 'fontawesome';
        position: absolute;
        top: 0;
        left: 0;
        color: $primary;
      }
    }

    ul {
      list-style: disc;
      padding-left: 17px;

      li {
        padding-left: 0;

        &:not(:last-child) {
          margin-bottom: 0;
        }

        &::before {
          display: none;
        }
      }
    }
  }
}

.course-tabs {
  padding: 1px;

  .nav-item {
    flex: 1;
  }

  .nav-link {
    text-align: center;
    color: #808080;
    font-size: 18px;
    padding: 0 20px;
    line-height: 48px;
    background-color: $white;
    box-shadow: 0 0 0 1px $border-color;

    &.active {
      color: $white;
      background-color: $primary;
      position: relative;
      box-shadow: 0 0 0 1px $primary;

      &::after {
        content: '';
        border: 15px solid transparent;
        border-top-color: $primary;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .nav-item:first-child .nav-link {
    border-radius: 4px 0 0 4px;
  }

  .nav-item:last-child .nav-link {
    border-radius: 0 4px 4px 0;
  }

  @media (max-width: 767px) {
    .nav-link {
      font-size: 14px;
      line-height: 43px;
      padding: 0 2px;

      &.active::after {
        border-width: 8px;
      }
    }
  }
}

.course-limit {
  position: relative;
  padding-bottom: 40px;

  &.is-collapse {
    overflow: hidden;
    max-height: 260px;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 60px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, transparent, #fff, #fff);
    }
  }

  &.show {
    max-height: unset;

    &::after {
      display: none;
    }
  }

  &__btn {
    display: none;
  }

  &.is-collapse &__btn {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 12;

    span:nth-child(2) {
      display: none;
    }
  }

  &.show &__btn {
    span:nth-child(1) {
      display: none;
    }

    span:nth-child(2) {
      display: inline-block;
    }

    .fa {
      transform: rotateX(180deg);
    }
  }
}

.course-description {
  flex: 1;
  position: relative;
  margin-bottom: 20px;

  &__inner {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 991px) {
    &__inner {
      position: static;
    }
  }
}
