.home-banner {
  position: relative;
  z-index: 1;
  color: $white;

  &__bg {
    display: block;
    width: 100%;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  &__title {
    font-size: 36px;
    font-weight: 900;
    margin-bottom: 10px;
  }

  &__desc {
    max-width: 505px;
    text-align: justify;
    margin-bottom: 40px;
  }

  &__btn {
    display: inline-block;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 50px;
    padding: 0 50px;
    border-radius: 4px;
    background-color: $primary;
    box-shadow: 0 1px 10px rgba(#000, 0.3);

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &__arrow {
    display: inline-flex;
    width: 46px;
    height: 46px;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    color: $white;
    background: 0;
    border: 1px solid $white;
    border-radius: 50%;
  }

  @media (max-width: 991px) {
    padding: 150px 0;

    &__bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    &__content {
      position: static;
      transform: translateY(0);
      text-align: center;
    }

    &__arrow {
      bottom: 40px;
    }

    &__desc {
      margin: 0 auto 30px;
      text-align: center;
    }
  }
}

.banner-nav {
  flex-direction: column;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  .nav-item {
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 20px;
    font-weight: 400;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    img {
      margin-left: 30px;
      height: 48px;
      width: 48px;
      background-color: $white;
      border-radius: 50%;
      object-fit: contain;
      object-position: 50% 50%;
      overflow: hidden;
    }

    &:hover img {
      animation: coin-rotation 1.5s linear infinite;
    }
  }

  @media (max-width: 991px) {
    position: static;
    transform: translateY(0);
    margin-top: 40px;

    .nav-item {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .nav-link {
      justify-content: flex-start;

      img {
        order: -1;
        margin-left: 0;
        margin-right: 20px;
      }
    }
  }
}

@keyframes coin-rotation {
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
  50% {
    transform: rotate3d(0, 1, 0, 180deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}

.section {
  &__title {
    font-size: 36px;
    font-weight: 900;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 0;
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 24px;
    }
  }
}

.course-row {
  display: flex;
  margin: 0 -15px;

  &__main {
    flex: 1;
    padding: 0 15px;
  }

  &__aside {
    flex: 0 0 330px;
    max-width: 330px;
    padding: 0 15px;
  }

  @media (max-width: 1199px) {
    flex-direction: column;

    &__aside {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.testimonial-nav {
  display: block;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 40px;

  .nav-item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  .nav-link {
    font-size: 14px;
    line-height: 38px;
    white-space: nowrap;
    padding: 0 26px;
    border-radius: 4px;
    border: 1px solid;
    color: $white;

    &:hover {
      background-color: rgba(#000, 0.05);
    }

    &.active {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  }

  @media (max-width: 767px) {
    .nav-item {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .nav-link {
      line-height: 33px;
      padding: 0 16px;
    }
  }
}

.steps {
  display: block;
  width: 100%;
  overflow: hidden;
  background: url('../images/world-map-bg.png') center/contain no-repeat,
    $secondary;
  color: $white;

  &__inner {
    display: block;
    width: 1080px;
    max-width: 100%;
    height: 650px;
    margin: 0 auto;
    position: relative;
  }

  &__route {
    position: absolute;
    top: 325px;
    left: 540px;
    transition: 0.8s ease-in-out;
    opacity: 0;
  }

  &:hover &__route {
    opacity: 1;
  }

  &:hover &__route:nth-child(1) {
    top: 115px;
  }
  &:hover &__route:nth-child(2) {
    top: 300px;
    left: 903px;
  }
  &:hover &__route:nth-child(3) {
    top: 517px;
    left: 806px;
  }
  &:hover &__route:nth-child(4) {
    top: 517px;
    left: 270px;
  }
  &:hover &__route:nth-child(5) {
    top: 300px;
    left: 177px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 320px;
    transform: translate(-50%, -50%);
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &__iwrap {
    display: flex;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    background-color: $white;
    align-self: center;
    border-radius: 50%;
    margin-bottom: 10px;
    color: $secondary;
    transition: transform 0.4s linear, background-color 0s 0.2s, color 0s 0.2s;
    font-size: 24px;
    font-weight: 900;
    animation: iwrapRotate-reverse 0.3s;
  }

  &__item:hover &__iwrap {
    // transform: rotateY(180deg);
    background-color: $primary;
    color: $white;
    animation: iwrapRotate 0.3s;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }

  &__desc {
    text-align: center;
  }

  &__center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 105px;
    }
  }

  &__header {
    text-align: center;
    font-size: 22px;
    font-weight: 900;
    padding: 10px 20px;
    margin-bottom: 0;
  }

  &__btn {
    display: inline-block;
    box-shadow: 0 1px 6px rgba(#000, 0.3);
    font-size: 13px;
    font-weight: 700;
    padding: 0 20px;
    line-height: 25px;
    border-radius: 4px;
    color: $white;
    background-color: $primary;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  @media (max-width: 1199px) {
    &__inner {
      display: flex;
      flex-direction: column;
      height: auto;
      padding: 60px 0;
    }

    &__center {
      order: -1;
      position: static;
      margin: 0 auto;
      transform: translate(0, 0);
    }

    &__route {
      position: static;
      margin-top: 30px;
      opacity: 1;
    }

    &__item {
      position: static;
      margin: 0 auto;
      transform: translate(0, 0);
    }
  }
}

@keyframes iwrapRotate {
  0% {
    transform: rotateY(0);
    background-color: $white;
    color: $primary;
  }
  49.9999% {
    transform: rotateY(90deg);
    background-color: $white;
    color: $primary;
  }
  50% {
    transform: rotateY(90deg);
    background-color: $primary;
    color: $white;
  }
  100% {
    transform: rotateY(0);
    background-color: $primary;
    color: $white;
  }
}

@keyframes iwrapRotate-reverse {
  100% {
    transform: rotateY(0);
    background-color: $primary;
    color: $white;
  }
  49.9999% {
    transform: rotateY(90deg);
    background-color: $primary;
    color: $white;
  }
  50% {
    transform: rotateY(90deg);
    background-color: $white;
    color: $primary;
  }
  100% {
    transform: rotateY(0);
    background-color: $white;
    color: $primary;
  }
}

.statistic {
  position: relative;
  z-index: 1;

  &__bg {
    display: block;
    width: 100%;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    color: $white;
  }

  &__iwrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 82px;
    height: 82px;
    border-radius: 50%;
    background-color: rgba(#fff, 0.3);
    margin-right: 30px;
  }

  &__name {
    font-size: 20px;
  }

  &__number {
    font-size: 36px;
    font-weight: 700;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    white-space: nowrap;
  }

  @media (max-width: 991px) {
    &__iwrap {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }

    &__name {
      font-size: 14px;
    }

    &__number {
      font-size: 24px;
    }
  }

  @media (max-width: 767px) {
    padding: 100px 0;

    &__bg {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }

    &__content {
      position: static;
    }

    &__inner {
      flex-direction: column;
    }

    &__item {
      flex-direction: column;
      align-items: center;
      text-align: center;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &__iwrap {
      margin-right: 0;
    }
  }
}

.testimonials {
  padding-bottom: 45px;
  position: relative;
  background: url('../images/testimonials-bg.jpg') center/cover no-repeat;
  color: $white;
}

.customer-comments {
  max-width: 880px;
  margin: 0 auto 6px;

  &__slide {
    padding: 16px 30px;
    position: relative;

    &:focus {
      outline: 0;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      border-bottom: 1px solid $secondary;
    }
  }

  &__open-quote {
    font-size: 26px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__close-quote {
    font-size: 26px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__detail {
    font-style: italic;
    text-align: center;
  }
}

.customer-avatars {
  max-width: 510px;
  margin: 0 auto;
  position: relative;

  &__slide {
    padding: 30px 0;
    transition: 0.3s;
    z-index: 1;

    &:focus {
      outline: 0;
    }

    &.slick-center {
      position: relative;
      transform: scale(1.3);
      z-index: 2;
    }
  }

  &__iwrap {
    @include iwrap(100%);
    width: 160px;
    max-width: 100%;
    border-radius: 50%;
    border: 1px solid $white;
    overflow: hidden;
    background-color: $white;
  }
}

.customer-info {
  max-width: 500px;
  margin: 20px auto 0;
  text-align: center;

  &__slide {
    &:focus {
      outline: 0;
    }
  }

  &__item {
    font-style: italic;
  }
}

.home-customer {
  padding: 40px 0;

  &__title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 50px;
  }

  &__desc {
    font-size: 14px;
    margin-bottom: 60px;
    max-width: 450px;
  }

  &__btn {
    @include btn-fill;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: 45px;
    border: 1px solid $primary;
    border-radius: 4px;
    padding: 0 54px;

    &:hover {
      text-decoration: none;
    }

    &::before {
      background-color: $primary;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  &__anchor {
    @include iwrap(100%);
    color: $white;
    min-height: 100%;

    &:hover {
      color: $white;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: $black;
      opacity: 0.7;
      transition: 0.3s;
    }

    &:hover::after {
      background-color: $primary;
    }
  }

  &__text {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 20px;
      margin-bottom: 20px;
      margin-top: 0;
    }

    &__desc {
      font-size: 16px;
      margin-bottom: 20px;
    }

    &__btn {
      line-height: 48px;
      border-width: 1px;
      font-size: 14px;
      margin-bottom: 50px;
    }

    &__text {
      font-size: 18px;
    }
  }
}

.study-program-wrapper {
  background-color: #dae2ee;
  overflow: hidden;
}

.study-program {
  width: calc(100% + 30px);
  margin: 0 -15px;
  position: relative;
  z-index: 1;

  .swiper-slide {
    padding: 15px;
  }

  &__prev,
  &__next {
    display: flex;
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;
    border: 1px solid $primary;
    border-radius: 50%;
    color: $primary;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: -80px;
    transform: translateY(calc(-50% - 36px));
    font-size: 24px;

    .fa {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      position: relative;
      top: 0;
      left: 0;
    }

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: -80px;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    padding: 15px 0 30px;
    margin: 0;

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      opacity: 1;
      margin: 0 10px;
      background-color: $white;
      @include shadow;

      &:focus {
        outline: 0;
      }

      &-active {
        background-color: $primary;
      }
    }
  }

  @media (max-width: 1400px) {
    &__prev {
      left: -60px;
    }

    &__next {
      right: -60px;
    }
  }

  @media (max-width: 575px) {
    &__prev,
    &__next {
      width: 40px;
      height: 40px;
      background-color: $primary;
      @include shadow;
      font-size: 14px;
      color: $white;
    }

    &__prev {
      left: 0;
    }

    &__next {
      right: 0;
    }
  }
}

.program {
  @include shadow;
  position: relative;
  z-index: 1;
  color: $white;
  border-radius: 4px;
  overflow: hidden;

  &__iwrap {
    @include iwrap(130%);
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 30%;
    padding: 10px 30px;
    background-color: rgba($secondary, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.5s;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  &__body {
    overflow: hidden;
    height: 0;
    transition: 0.5s;
  }

  &:hover &__body {
    height: 200px;
  }

  &:hover &__overlay {
    height: 100%;
  }

  &__desc {
    font-size: 14px;
    margin-bottom: 24px;
  }

  &__note {
    font-size: 14px;
    padding-left: 30px;
    position: relative;
    margin-bottom: 30px;

    &::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background-color: $white;
      position: absolute;
      left: 0;
      top: 4px;
    }
  }

  &__btn {
    @include btn-fill;
    display: inline-block;
    font-size: 16px;
    padding: 0 45px;
    line-height: 43px;
    border: 1px solid $white;
    border-radius: 4px;
    color: $white;

    &:hover {
      color: $gray-800;
      text-decoration: none;
    }

    &::before {
      background-color: $white;
    }
  }

  @media (max-width: 1199px) {
    &__overlay {
      padding: 12px;
    }

    &__title {
      font-size: 16px;
      margin-bottom: 12px;
    }

    &__desc {
      margin-bottom: 12px;
    }

    &__note {
      margin-bottom: 12px;

      &::before {
        top: 3px;
      }
    }

    &:hover &__body {
      height: 160px;
    }

    &__btn {
      line-height: 35px;
    }
  }

  @media (max-width: 767px) {
    &__overlay {
      height: 40%;
    }
  }

  @media (max-width: 575px) {
    &__overlay {
      height: 30%;
    }

    &:hover &__body {
      height: 180px;
    }

    &__btn {
      line-height: 39px;
    }
  }
}

.certificate {
  border-radius: 4px;
  background-color: $primary;
  padding: 16px;

  img {
    display: block;
    width: 100%;
  }
}
