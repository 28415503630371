.b-project {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 50px;

  &__frame {
    @include iwrap(75%);
    overflow: hidden;

    img {
      transition: 0.4s ease-out;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }

  &__body {
    flex-grow: 1;
    position: relative;
    padding: 30px 30px 50px;
    background-color: $b-dark;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 8px;
      background: linear-gradient(
        to right,
        #bb7e2d,
        #fef6ac,
        #bb7e2d,
        #fef6ac,
        #bb7e2d
      );
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    color: $white;
    margin-bottom: 10px;

    a,
    a:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  &__time {
    display: inline-block;
    color: #ccc;
    font-size: 13px;
    border-bottom: 1px solid $white;
    margin-bottom: 20px;
  }

  &__info {
    font-size: 14px;
    line-height: 30px;
    color: $white;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 8px;
  }

  &__icon {
    width: 26px;
    height: 26px;
    color: $b-brown;
    margin-right: 8px;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__label {
    font-size: 12px;
    color: #aaa;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 4px;
  }

  &__text {
    font-size: 13px;
    font-weight: 700;
    line-height: 1.2;
  }

  &__btn {
    @include btn-shine;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 50px;
    line-height: 50px;
    border: 0;
    border-radius: 2px;
    background: $b-brown;
    white-space: nowrap;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    .fa {
      position: relative;
      top: -1px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__price {
    font-size: 24px;
    color: $b-brown;
    line-height: 1;
  }

  &__state {
    display: inline-block;
    background-color: $white;
    font-size: 12px;
    line-height: 24px;
    padding: 0 10px 0 8px;
    margin-left: 20px;
    position: relative;
    text-transform: uppercase;
    color: #444;
    transition: 0.2s;

    &:hover {
      color: #444;
      text-decoration: none;
    }

    &::before {
      content: "";
      border: 12px solid transparent;
      border-right-color: $white;
      position: absolute;
      top: 0;
      right: 100%;
      transition: 0.2s;
    }

    &:hover {
      background-color: $b-brown;
      color: $white;

      &::before {
        border-right-color: $b-brown;
      }
    }
  }

  @media (max-width: 575px) {
    &__body {
      padding: 30px 16px 50px;
    }
  }
}

.b-social {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__link {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border: 2px solid $b-brown;
    border-radius: 2px;
    font-size: 16px;
    color: $b-brown;
    transition: 0.3s;

    &:hover {
      color: $b-dark;
      background-color: $b-brown;
      text-decoration: none;
    }
  }
}

.b-menu {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 -8px;

  &__item {
    padding: 0 8px;
    flex: 0 0 50%;
    max-width: 50%;
  }

  &__link {
    font-size: 14px;
    line-height: 24px;
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

.b-contact {
  list-style: none;
  margin: 0;
  padding: 0;
  color: $white;

  &__item {
    position: relative;
    padding-left: 26px;
    font-size: 14px;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    & > .fa {
      position: absolute;
      top: 4px;
      left: 0;
    }

    a,
    a:hover {
      color: inherit;
    }
  }
}

.b-paging-wrap {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .page-link {
    font-weight: 700;

    &:hover {
      color: $b-brown;
    }
  }

  .page-item.active .page-link {
    background-color: $b-brown;
  }
}

.b-result {
  &__inner {
    padding: 30px 0;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }

  &__text {
    margin-bottom: 30px;
    font-size: 18px;
  }
}

.b-title {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 16px;
  margin-bottom: 50px;
  position: relative;
  z-index: 2;

  &::before {
    content: "";
    display: block;
    width: 90px;
    border-bottom: 3px solid $b-brown;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 991px) {
    font-size: 24px;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
}

.b-section-2 {
  padding: 100px 0 0;

  @media (max-width: 991px) {
    padding: 50px 0 0;
  }
}

.b-section-3 {
  padding: 50px 0;
}

.b-filter {
  position: relative;
  z-index: 10;

  &__float {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 100%;
  }

  &__search {
    width: 350px;
    max-width: 100%;
    align-items: center;
    background-color: $b-dark;

    .form-control {
      height: 50px !important;
      border: 0;
      border-radius: 2px 0 0 2px;
      padding: 0 20px !important;
      background-color: #393e41 !important;
      color: $white;

      &::placeholder {
        color: $white;
      }
    }

    .input-group-text {
      background-color: #24292c;
      padding: 0 20px;
      border: 0;
      border-radius: 0 2px 2px 0;

      img {
        height: 30px;
      }
    }
  }

  &__search-label {
    display: block;
    width: 200px;
    font-size: 14px;
    color: $white;
    margin: 0;
    padding: 0 50px 0 30px;
    white-space: nowrap;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    padding: 20px;
    background-color: #dadadc;
  }

  &__switches {
    display: flex;
    position: absolute;
    bottom: 100%;
    left: 0;
    margin-bottom: 4px;
  }

  &__label {
    display: block;
    color: $white;
  }

  &__btn {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    border: 0;
    background: $b-brown;
    color: $white;
    padding: 0 10px;
    font-size: 16px;
    transition: 0.3s;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken($b-brown, 5%);
    }

    img {
      height: 30px;
    }
  }

  .form-control {
    height: 40px;
    border-radius: 0;
    border: 0;
    background-color: $white;
    font-size: 14px;
    padding: 5px;
  }

  select.form-control {
    padding-left: 20px;
  }

  @media (max-width: 991px) {
    &__grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 767px) {
    padding-top: 20px;

    &__float {
      position: static;
      margin-bottom: 20px;
    }

    &__search {
      width: 100%;
      flex-direction: column;
      align-items: stretch;

      .form-control {
        text-align: left;
        padding: 0 12px;
      }
    }

    &__search-label {
      padding: 0;
      margin-bottom: 10px;
    }

    &__grid {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 8px;
    }

    select.form-control {
      padding-left: 12px;
    }
  }
}

.b-container {
  @media (min-width: 1200px) {
    max-width: 1280px;
  }
}

.b-projects {
  &__inner {
    padding: 100px 0 30px;
  }

  &__frame {
    @include iwrap(65%);
    @include hover-zoom;
    @include shadow-md;
    margin-top: 80px;
    z-index: 10;
    left: -50px;
  }

  &__content {
    position: relative;
    z-index: 1;
    left: 20px;
    padding: 80px 40px 120px 30px;

    &::before {
      content: "";
      background: linear-gradient(
        to right,
        #bb7e2d,
        #fef6ac,
        #bb7e2d,
        #fef6ac,
        #bb7e2d
      );
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &::after {
      content: "";
      background: $white;
      position: absolute;
      z-index: -1;
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;

    a,
    a:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__price {
    font-size: 24px;
    color: $b-brown;
    line-height: 1;
  }

  &__state {
    display: inline-block;
    background-color: $b-brown;
    font-size: 12px;
    line-height: 24px;
    padding: 0 10px 0 8px;
    margin-left: 20px;
    position: relative;
    text-transform: uppercase;
    color: $white;
    transition: 0.2s;

    &:hover {
      color: #444;
      text-decoration: none;
    }

    &::before {
      content: "";
      border: 12px solid transparent;
      border-right-color: $b-brown;
      position: absolute;
      top: 0;
      right: 100%;
      transition: 0.2s;
    }

    &:hover {
      background-color: $black;
      color: $white;

      &::before {
        border-right-color: $black;
      }
    }
  }

  &__time {
    display: inline-block;
    color: #666;
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
  }

  &__info {
    font-weight: 700;
    line-height: 30px;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 8px;
  }

  &__icon {
    width: 26px;
    height: 26px;
    color: $b-brown;
    margin-right: 8px;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__label {
    font-size: 12px;
    color: #aaa;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 4px;
  }

  &__text {
    font-size: 13px;
    font-weight: 700;
    line-height: 1.2;
  }

  &__btn {
    @include btn-shine;
    display: inline-block;
    line-height: 50px;
    border: 0;
    padding: 0 50px;
    background-color: $b-brown;
    color: $white;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;
    position: absolute;
    top: 100%;
    left: 45%;
    transform: translate(-50%, -50%);
    border-radius: 2px;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    .fa {
      position: relative;
      top: -1px;
    }
  }

  @media (max-width: 1199px) {
    &__inner {
      padding-top: 50px;
    }
  }

  @media (max-width: 991px) {
    &__frame {
      margin: 0;
      left: 0;
    }

    &__content {
      max-width: 400px;
      padding: 80px 40px 100px;
      margin: 0 auto 30px;
      left: 0;
    }

    &__btn {
      left: 50%;
    }
  }

  @media (max-width: 575px) {
    &__content {
      padding: 80px 20px 100px;
    }
  }
}

.b-methods {
  padding-top: 50px;
  overflow: hidden;
}

.b-pros {
  padding-top: 50px;
  overflow: hidden;
}

.b-banner {
  img {
    display: block;
    width: 100%;
  }
}

.b-tabs {
  box-shadow: inset 0 -1px 0 $b-brown;
  .nav-item {
    flex: 1;
  }

  .nav-link {
    text-align: center;
    font-size: 14px;
    line-height: 40px;
    padding: 0 20px;
    font-weight: 700;
    color: $b-brown;
    border: 1px solid transparent;
    white-space: nowrap;

    &.active {
      border-color: $b-brown $b-brown $white $b-brown;
    }
  }

  @media (max-width: 1199px) {
    display: block;
    white-space: nowrap;
    overflow: auto;

    .nav-item {
      display: inline-block;
    }
  }
}

.b-post {
  padding: 20px;
}

.b-relate {
  border-top: 1px solid $border-color;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;

  &::before {
    content: "";
    display: block;
    width: 80px;
    border-top: 3px solid $b-brown;
    position: absolute;
    top: -2px;
    right: 0;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    padding-left: 30px;
    position: relative;
    z-index: 1;

    &::before,
    &::after {
      content: "";
      border: 12px solid transparent;
      border-width: 8px 12px;
      border-left-color: $b-brown;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &::before {
      left: 3px;
      border-left-color: #fff;
    }

    span {
      display: inline-block;

      &::before {
        content: "";
        border: 8px solid transparent;
        border-width: 6px 8px;
        border-left-color: $b-brown;
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
      }
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    position: relative;
    padding-left: 30px;

    &::before {
      content: "\f105\f105\f105 ";
      font-family: "fontawesome";
      position: absolute;
      top: 0;
      left: 0;
      color: $b-brown;
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__link {
    display: inline-block;
    color: $gray-800;

    &:hover {
      color: $b-brown;
      text-decoration: none;
    }
  }
}

.b-preview {
  &__item {
    position: relative;
  }

  &__frame {
    @include iwrap(56.25%);
  }

  &__overlay {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(#000, 0.15);
    color: $white;
    position: absolute;
    top: 0;
    left: 0;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &--video::before {
      content: "\f04b";
      font-family: "fontawesome";
      display: flex;
      width: 80px;
      height: 80px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid $white;
      font-size: 30px;
      padding-left: 2px;
    }
  }
}

.b-thumb {
  margin-top: 16px;

  &__frame {
    @include iwrap(56.25%);

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.7);
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
    }
  }

  .swiper-slide {
    cursor: pointer;
  }

  .swiper-slide-thumb-active &__frame::after {
    display: none;
  }
}

.b-info {
  &__info {
    width: 100%;

    tbody {
      td {
        font-weight: 700;
        padding-bottom: 10px;

        &:first-child {
          white-space: nowrap;
          padding-right: 40px;
        }

        &:nth-child(2) {
          color: $b-brown;
        }
      }
    }
  }

  &__code {
    padding: 10px 12px;
    text-align: center;
    font-weight: 700;
    font-style: italic;
    color: $b-brown;
    border-radius: 2px;
    background-color: #ecdac2;
  }

  &__btn {
    display: block;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 60px;
    padding: 0 25px;
    color: $white;
    background-color: $b-brown;
    border: 0;
    border-radius: 4px;
    transition: 0.3s;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken($b-brown, 10%);
    }

    &--gray {
      background-color: #a3a3a5;

      &:hover {
        background-color: #777;
      }
    }
  }
}

.b-stars {
  display: inline-flex;
  letter-spacing: 15px;

  &::before {
    content: "\f005\f005\f005\f005\f005 ";
    font-family: "fontawesome";
    color: inherit;
  }

  &::after {
    content: "";
    font-family: "fontawesome";
    color: #a3a3a5;
  }

  &-1 {
    &::before {
      content: "\f005";
    }

    &:after {
      content: "\f005\f005\f005\f005";
    }
  }

  &-2 {
    &::before {
      content: "\f005\f005";
    }

    &:after {
      content: "\f005\f005\f005";
    }
  }

  &-3 {
    &::before {
      content: "\f005\f005\f005";
    }

    &::after {
      content: "\f005\f005";
    }
  }

  &-4 {
    &::before {
      content: "\f005\f005\f005\f005";
    }
    &::after {
      content: "\f005";
    }
  }

  &-5::before {
    content: "\f005\f005\f005\f005\f005";
  }
}

.b-supplier {
  background-color: $b-light;

  &__title {
    font-size: 14px;
    font-weight: 700;
    padding: 12px 20px;
    border-bottom: 1px solid #ccc;
  }

  &__body {
    padding: 20px;
  }

  &__thumbnail {
    display: block;
    width: 100px;
    position: relative;
    border: 1px solid $b-brown;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
    background-color: $white;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  &__stars {
    color: $b-brown;
    margin-bottom: 4px;
  }

  &__item {
    font-weight: 700;
    color: $b-brown;
  }

  &__label {
    display: inline-block;
    font-weight: 400px;
    color: $gray-800;
    min-width: 120px;
  }
}

.b-guide {
  flex-direction: column;

  .nav-item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .nav-link {
    padding: 30px 20px;
    background-color: $b-brown;
    color: $white;
    text-align: center;
    font-weight: 700;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken($b-brown, 5%);
    }

    &.active {
      background-color: darken($b-brown, 10%);
    }
  }

  @media (max-width: 991px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;

    .nav-item {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    .nav-link {
      display: flex;
      flex-direction: column;
      padding: 8px;
      font-size: 13px;
      height: 100%;
      justify-content: center;
    }
  }
}

.b-content {
  padding: 0 30px 50px;
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 400%;
    background-color: #ededed;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  &::after {
    content: "";
    display: block;
    height: 100%;
    width: 2px;
    background-color: #333;
    position: absolute;
    top: 0;
    right: 0;
  }

  &.left-border::after {
    right: auto;
    left: 0;
  }

  &__expand,
  &__collapse {
    position: absolute;
    z-index: 1;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    color: $b-brown;

    &:hover {
      color: $b-brown;
      text-decoration: none;
    }
  }

  &__collapse {
    display: none;
  }

  &__text {
    height: 390px;
    overflow: hidden;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 30px;
      background: linear-gradient(to top, #ededed, transparent);
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &.active {
      height: auto;

      &::after {
        display: none;
      }
    }
  }

  @media (max-width: 991px) {
    padding: 30px 20px 50px;

    &::before {
      height: 100%;
    }
  }
}

.b-btn {
  @include btn-fill;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: $b-brown;
  display: inline-block;
  line-height: 50px;
  border: 1px solid $b-brown;
  border-radius: 2px;
  padding: 0 30px;

  &:hover {
    color: $white;
    text-decoration: none;
  }

  &::before {
    background-color: $b-brown;
  }
}

.b-paging {
  display: flex;
  justify-content: center;

  &__pages {
    font-size: 16px;

    input {
      width: 40px;
      height: 30px;
      text-align: center;
      border-radius: 2px;
      margin-left: 10px;
      margin-right: 4px;
      border: 1px solid #444;
    }
  }

  &__nav {
    display: flex;
    margin-left: 30px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $b-brown;
    color: $white;
    font-size: 20px;
    border: 0;
    line-height: 30px;
    padding: 0 20px;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken($b-brown, 5%);
    }
  }
}
