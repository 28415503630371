.recruitment-intro {
  padding: 50px 0 60px;

  &__title {
    font-size: 36px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 14px;
    line-height: 1.2;
  }

  &__desc {
    max-width: 890px;
    margin: 0 auto;
    text-align: center;
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 24px;
    }
  }
}

.recruitment-widget {
  padding: 28px 0;
  margin-bottom: 140px;
  background: url('../images/recruitment-widget-bg.jpg') center/cover no-repeat;

  &__inner {
    position: relative;
    top: 140px;
  }

  &__panel {
    height: 100%;

    &--left {
      padding: 40px 0 40px 10px;
    }

    &--right {
      padding: 40px 10px 40px 0;
    }
  }

  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    padding: 24px;
    background-color: rgba($secondary, 0.7);
  }

  &__panel--center &__content {
    border-radius: 20px;
    background-color: rgba($primary, 0.8);
  }

  &__panel--left &__content {
    border-radius: 20px 0 0 20px;
  }

  &__panel--right &__content {
    border-radius: 0 20px 20px 0;
  }

  &__title {
    font-size: 36px;
    text-align: center;
    line-height: 1.2;
    font-weight: 700;
  }

  &__iwrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 125px;
    margin-bottom: 8px;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__number {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 14px;
  }

  &__label {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }

  &__logo {
    width: 300px;
    max-width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 991px) {
    &__iwrap {
      height: 100px;
    }

    &__number {
      font-size: 30px;
    }

    &__label {
      font-size: 14px;
    }

    &__title {
      font-size: 24px;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 80px;

    &__panel {
      &--left {
        padding: 20px 0 20px;
      }

      &--right {
        padding: 20px 0 20px;
      }
    }

    &__inner {
      top: 80px;
      margin: 0 -5px;
    }

    &__content {
      padding: 20px 5px;
    }

    &__iwrap {
      height: 60px;
    }

    &__title {
      font-size: 16px;
    }

    &__number {
      font-size: 16px;
      margin-bottom: 8px;
    }

    &__label {
      font-size: 12px;
    }
  }
}

.recruitment-feedback {
  background-color: #f3f3f3;
  padding: 50px 0 100px;
}

.recruitment-partner {
  margin: 0 0 20px 46px;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 20px;
    left: -20px;
    background-color: $primary;
  }

  img {
    display: block;
  }
}

.recruitment-card {
  &__header {
    font-size: 16px;
    font-weight: 700;
    padding: 16px 64px 16px 24px;
    background-color: $secondary;
    color: $white;
    border-radius: 4px;
    transition: 0.3s;
    cursor: pointer;
    position: relative;

    &.active {
      background-color: $primary;
      border-radius: 4px 4px 0 0;
    }
  }

  &__icon {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 60px;

    span {
      display: inline-block;
      width: 16px;
      height: 3px;
      background-color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s;

      &:nth-child(2) {
        transform: translate(-50%, -50%) rotateZ(90deg);
      }
    }
  }

  &__header.active &__icon span:nth-child(2) {
    transform: translate(-50%, -50%) rotateZ(90deg) rotateX(90deg);
  }

  &__body {
    display: none;
    padding: 24px;
    background-color: #f3f3f3;
  }

  &__img {
    margin-right: 30px;
  }

  @media (max-width: 767px) {
    &__grid {
      flex-direction: column;
    }

    &__img {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}
