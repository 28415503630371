.header {
  position: relative;
  z-index: 100;

  &__wrapper {
    &--float {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__logo {
    display: inline-flex;
    height: 100px;
    align-items: center;
    margin-right: auto;

    img {
      height: 68px;
      max-height: 100%;
    }
  }

  &__btn {
    display: inline-block;
    border: 1px solid $border-color;
    border-radius: 4px;
    font-size: 16px;
    line-height: 31px;
    color: $gray-800;
    padding: 0 10px 2px;
    transition: 0.3s;

    &:hover {
      color: $gray-800;
      text-decoration: none;
    }

    &--action {
      margin-left: auto;

      &:hover {
        color: $white;
        background-color: $primary;
        border-color: $primary;
      }
    }

    &--black {
      border-color: $gray-800;
    }

    &--primary {
      border-color: $primary;
      color: $primary;
    }

    &--white {
      border-color: $white;
      color: $white;
    }

    &--menu {
      margin-left: 10px;
      color: $white;
      background-color: $primary;
      border-color: $primary;

      &:hover {
        color: $white;
      }
    }
  }

  @media (min-width: 1200px) {
    &__inner {
      position: relative;
    }

    &__btn {
      &--action {
        position: absolute;
        top: 50%;
        right: 78px;
        transform: translateY(-50%);
      }

      &--menu {
        display: none;
      }
    }

    &--course-page &__btn--action {
      right: 0;
      border-radius: 4px;
    }

    &--course-page .menu-root {
      margin-right: 100px;
    }
  }
}

.lang {
  display: flex;
  justify-content: center;
  padding: 0 20px 10px;

  &__item {
    display: inline-flex;

    &:not(:last-child) {
      margin-right: 10px;
    }

    img {
      height: 30px;
    }
  }

  @media (min-width: 1200px) {
    order: 1;
    padding: 0;
    margin-left: 132px;

    &__item {
      img {
        height: 18px;
      }
    }
  }
}

.menu-root {
  @media (min-width: 1200px) {
    & > .menu-item {
      &:not(:last-child) {
        position: relative;

        &::after {
          content: "";
          display: block;
          height: 15px;
          border-right: 1px solid rgba(#000, 0.2);
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }

      &.menu-item-group > .menu-toggle {
        display: none;
      }

      & > .menu-link {
        font-size: 16px;
        padding: 0 30px;
      }

      &.menu-item-group > .menu-link {
        padding-right: 20px;
      }

      &:hover > .menu-link {
        background: 0;
      }
    }

    &.menu-expand-white {
      & > .menu-item {
        &:not(:last-child):after {
          border-color: rgba($white, 0.5);
        }

        & > .menu-link {
          color: $white;
        }
      }
    }
  }
}

.search {
  display: flex;
  padding: 10px 20px;
  position: relative;

  &__input {
    flex: 1;
    height: 40px;
    border: 1px solid $border-color;
    border-radius: 4px;
    padding: 0 40px 0 25px;
  }

  &__btn {
    width: 40px;
    height: 40px;
    border-radius: 0 4px 4px 0;
    border: 0;
    background-color: $primary;
    color: $white;
    position: absolute;
    top: 10px;
    right: 20px;
  }

  @media (min-width: 1200px) {
    width: 425px;
    padding: 0;

    &__input {
      background: 0;
      font-size: 16px;
      color: $white;

      &::placeholder {
        color: $white;
      }
    }

    &__btn {
      top: 0;
      right: 0;
    }
  }
}

.h-button {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 48px;
  color: $white;
  border: 1px solid $white;
  border-radius: 25px;
  padding: 0 42px;

  &:hover {
    color: $white;
    text-decoration: none;
  }
}

.h-lang {
  .dropdown-toggle {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    line-height: 48px;
    padding: 0 20px;
    margin-right: 30px;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &:after {
      display: none;
    }
  }
}

.header-blog-detail {
  border-bottom: 1px solid $border-color;

  .search {
    &__input {
      color: $gray-800;

      &::placeholder {
        color: #999;
      }
    }
  }

  @media (min-width: 1200px) {
    .menu.menu-expand-xl {
      .menu-link {
        color: $gray-800;
      }
    }
  }
}
