.b-header {
  background-color: $b-dark;
  position: relative;
  z-index: 100;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__logo {
    display: inline-flex;
    margin: 18px 0;

    img {
      height: 44px;
    }
  }

  &__btn {
    margin-left: auto;
    background-color: $border-color;

    &:focus {
      border-color: #fff;
      color: $gray-800;
    }
  }

  @media (min-width: 1200px) {
    &__btn {
      display: none;
    }
  }
}

.b-elements {
  display: none;
  background-color: $b-dark;
  padding: 0 0 15px;
  border-top: 2px solid $b-brown;
  box-shadow: 0 1px 3px rgba(#000, 0.15);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;

  @media (min-width: 1200px) {
    display: block;
    position: static;
    box-shadow: unset;
    padding: 0;
    border: 0;
    flex: 1;
    margin-left: 60px;

    &__inner {
      display: flex;
      align-items: center;
    }

    .container {
      max-width: 100%;
      padding: 0;
    }
  }
}

.b-search {
  width: 100%;
  margin-top: 16px;

  .form-control {
    height: 40px;
    border-radius: 0;
    background-color: $b-light;
    border: 0;
    padding: 0 24px;
  }

  .input-group-text {
    width: 78px;
    justify-content: center;
    background-color: $b-brown;
    border: 0;
    color: $white;
    font-size: 16px;
    border-radius: 0;
  }

  @media (min-width: 1200px) {
    order: -1;
    margin-top: 0;
    flex: 1;
  }
}

.b-nav {
  list-style: none;
  margin: 0 0;
  padding: 0;

  &__item {
    &:not(:last-child) {
      border-bottom: 1px dashed #999;
    }
  }

  &__link {
    display: inline-block;
    padding: 12px 0;
    color: $b-brown;
    font-size: 15px;
    font-weight: 700;

    &:hover {
      color: $b-brown;
      text-decoration: none;
    }
  }

  &__sub {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__sub-link {
    display: inline-block;
    color: $b-brown;
    padding: 8px 0 8px 30px;
    position: relative;

    &:hover {
      color: $b-brown;
      text-decoration: none;
    }

    &::before {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      background-color: $b-brown;
      position: absolute;
      top: 17px;
      left: 16px;
    }
  }

  @media (min-width: 1200px) {
    display: flex;
    margin-left: 50px;

    &__item {
      position: relative;

      &:not(:last-child) {
        border: 0;
        margin-right: 61px;

        &::after {
          content: "";
          display: block;
          height: 8px;
          border-right: 1px solid $b-brown;
          position: absolute;
          top: 50%;
          right: -31px;
          transform: translateY(-50%);
        }
      }
    }

    &__link {
      white-space: nowrap;
      padding: 12px 0;
    }

    &__toggle {
      &::after {
        content: "\f0c9";
        font-family: "fontawesome";
        margin-left: 16px;
      }
    }

    &__sub {
      min-width: 160px;
      background-color: $white;
      box-shadow: 0 1px 3px rgba(#000, 0.3);
      padding: 6px 0;
      border-radius: 2px;
      position: absolute;
      top: 100%;
      right: 0;
    }

    &__sub-item {
      &:not(:last-child) {
        border-bottom: 1px dashed $border-color;
      }
    }

    &__sub-link {
      display: block;
      transition: 0.3s;
      font-weight: 700;

      &:hover {
        background-color: $border-color;
      }
    }
  }
}
