.m-card {
  img {
    display: block;
    width: 100%;
  }
}

.study-level {
  padding-left: 30px;

  &__iwrap {
    margin-bottom: 20px;
    position: relative;
    color: $primary;

    img {
      height: 48px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -30px;
      border-left: 3px solid;
      height: 100%;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__desc {
    text-align: justify;
    margin-bottom: 14px;
  }
}

.course {
  position: relative;
  z-index: 1;
  overflow: hidden;

  &__iwrap {
    @include iwrap(77%);
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 0;
    max-width: 90%;
    padding: 8px 20px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
    background-color: rgba(#fff, 0.9);
    border-radius: 0 10px 10px 0;
    text-transform: uppercase;
    color: $secondary;
    transition: 0.3s 0.3s;
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-top: 2px solid $primary;
    background-color: rgba(#fff, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transform: translateY(40%);
    transition: 0.3s 0.2s;
  }

  &:hover &__title {
    transform: translateY(20%);
    opacity: 0;
    transition: 0.3s;
  }

  &:hover &__overlay {
    transform: translateY(0);
    opacity: 1;
    transition: 0.3s;
  }

  &__title-2 {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    color: $secondary;
    padding-bottom: 10px;
    margin-bottom: 14px;

    &::after {
      content: '';
      display: block;
      width: 50px;
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom: 2px solid $secondary;
    }
  }

  &__desc {
    @include max-line(2);
    margin-bottom: 8px;
  }

  &__apply {
    margin-top: auto;
    margin-bottom: 8px;

    .fa {
      color: $primary;
    }
  }

  &__link {
    display: inline-block;
    color: $primary;
    align-self: flex-start;

    &:hover {
      color: $secondary;
      text-decoration: none;
    }
  }

  @media (max-width: 575px) {
    &__title {
      padding: 6px 10px;
      font-size: 12px;
    }

    &__overlay {
      padding: 8px;
    }

    &__title-2 {
      font-size: 14px;
      margin-bottom: 8px;
    }

    &__desc {
      display: none;
    }
  }
}

.course-2 {
  padding: 24px 20px 24px 0;

  &__left {
    display: flex;
    justify-content: space-between;
    width: 80px;
    margin-right: 16px;
  }

  &__label {
    display: block;
    width: 23px;
    height: 39px;
    background: url('../images/icon-label-grey.png') center/contain no-repeat;
    margin-top: 5px;
  }

  &.active &__label {
    background-image: url('../images/icon-label-blue.png');
  }

  &__apply {
    width: 38px;
    font-size: 10px;
    text-align: center;
    margin-top: 2px;
  }

  &__apply-icon {
    display: flex;
    width: 38px;
    height: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url('../images/icon-calendar-blue.png') center/contain no-repeat;
    color: $primary;
    font-weight: 700;
    font-size: 14px;
    padding-top: 2px;
  }

  &__prefix {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px;
    margin-right: 16px;
    text-align: center;
    font-size: 10px;
    line-height: 1;

    img {
      margin-bottom: 10px;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    color: $primary;
  }

  &__feature {
    font-size: 12px;
    line-height: 20px;
    padding: 2px 0;

    .fa {
      color: $primary;
    }
  }

  &__btn {
    @include btn-fill;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    padding: 0 16px;
    line-height: 33px;
    color: $primary;
    border: 1px solid $primary;
    border-radius: 4px;
    margin-top: 20px;
    margin-left: 50px;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &::before {
      background-color: $primary;
    }
  }

  @media (max-width: 1199px) {
    padding: 20px;
  }

  @media (max-width: 575px) {
    position: relative;
    flex-direction: column;
    padding-left: 60px;

    &__left {
      width: 50px;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 20px;
      left: 0;
    }

    &__apply {
      margin-top: 16px;
    }

    &__btn {
      margin-top: 10px;
      margin-left: 0;
      align-self: flex-start;
    }
  }
}

.course-3 {
  padding: 22px 0;
  align-items: center;

  &__prefix {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 56px;
    padding-top: 10px;
    margin: 0 130px 0 0;
    text-align: center;
    font-size: 11px;
    line-height: 1;

    img {
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    color: $primary;
    margin-bottom: 6px;
  }

  &__feature {
    font-size: 14px;
    line-height: 20px;
    padding: 4px 0 4px 30px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 6px;
      left: 0;
      background-color: $primary;
    }
  }

  &__btn {
    @include btn-fill;
    display: inline-block;
    padding: 0 30px;
    font-size: 14px;
    line-height: 48px;
    color: $primary;
    border: 1px solid $primary;
    border-radius: 4px;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &::before {
      background-color: $primary;
    }
  }

  @media (max-width: 991px) {
    &__prefix {
      margin: 0 30px 0 0;
      padding-top: 6px;
    }

    &__title {
      font-size: 16px;
    }

    &__feature {
      font-size: 14px;

      &::before {
        width: 16px;
        height: 16px;
      }
    }

    &__btn {
      line-height: 43px;
    }
  }

  @media (max-width: 575px) {
    position: relative;
    flex-direction: column;
    padding-left: 70px;

    &__prefix {
      width: 50px;
      position: absolute;
      top: 25px;
      left: 0;
    }

    &__btn {
      margin-top: 10px;
      margin-left: 0;
      align-self: flex-start;
    }
  }
}

.course-4 {
  &__apply {
    display: flex;
    flex-wrap: wrap;
    font-size: 35px;
    font-weight: 700;
    line-height: 1;
    color: $primary;

    img {
      height: 30px;
      margin-left: 6px;
      opacity: 0.5;
      position: relative;
      top: -2px;
    }
  }

  &__apply-text {
    display: flex;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: $gray-800;
  }

  &__title {
    font-size: 15px;
    font-weight: 700;
    color: $gray-800;
    margin-top: 10px;
    margin-bottom: 6px;

    a:hover {
      color: $primary;
    }
  }

  &__feature {
    font-size: 13px;
    line-height: 20px;
    padding: 2px 0;
    position: relative;

    & > .fa {
      color: $primary;
    }
  }

  &__btn {
    @include btn-fill;
    display: inline-block;
    padding: 0 30px;
    font-size: 14px;
    line-height: 38px;
    color: $primary;
    border: 1px solid $primary;
    border-radius: 4px;
    margin-top: 10px;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &::before {
      background-color: $primary;
    }
  }
}

.course-5 {
  &__left {
    width: 70px;
    margin-right: 8px;
  }

  &__apply {
    display: flex;
    flex-wrap: wrap;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    color: $primary;

    img {
      height: 20px;
      margin-left: 6px;
      opacity: 0.5;
      position: relative;
      top: -2px;
    }
  }

  &__apply-text {
    display: flex;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: $gray-800;
  }

  &__title {
    font-size: 15px;
    font-weight: 700;
    color: $gray-800;
    margin-bottom: 6px;

    a:hover {
      color: $primary;
    }
  }

  &__feature {
    font-size: 12px;
    line-height: 20px;
    padding: 2px 0;
    position: relative;

    & > .fa {
      color: $primary;
    }
  }

  &__right {
    align-self: flex-end;
    margin-left: 8px;
  }

  &__btn {
    @include btn-fill;
    display: inline-block;
    padding: 0 12px;
    font-size: 13px;
    line-height: 28px;
    color: $primary;
    border: 1px solid $primary;
    border-radius: 4px;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &::before {
      background-color: $primary;
    }
  }

  @media (max-width: 575px) {
    flex-direction: column;

    &__left {
      margin-bottom: 4px;
    }

    &__right {
      margin-top: 4px;
    }
  }
}

.course-list {
  list-style: none;
  margin: 0;
  padding-left: 12px;

  &__item {
    position: relative;

    &:not(:last-child) {
      padding-bottom: 10px;
      border-bottom: 1px solid $border-color;
    }

    &:not(:first-child) {
      padding-top: 10px;
    }

    &::before {
      content: '';
      display: block;
      height: 100%;
      border-left: 2px solid $primary;
      position: absolute;
      top: 0;
      left: -12px;
    }

    &:nth-child(even)::before {
      border-left-color: #f8b461;
    }
  }
}

.course-grid {
  overflow: hidden;
  padding-left: 23px;
  position: relative;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 'auto';
    grid-gap: 31px;
  }

  &__cell {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: calc(100% + 30px);
      border-top: 1px solid $border-color;
      position: absolute;
      top: -16px;
      left: 0;
    }

    &::after {
      content: '';
      display: block;
      height: 100%;
      border-left: 1px solid $border-color;
      position: absolute;
      top: 0;
      right: -16px;
    }
  }

  &__border {
    display: none;
    height: calc(100% + 30px);
    width: 3px;
    background-color: $primary;
    position: absolute;
    top: -16px;
    left: -22px;
  }

  &__cell:nth-child(4n - 1) &__border {
    background-color: #f8b461;
  }

  &__cell:nth-child(2n + 1) &__border {
    display: block;
  }

  @media (max-width: 575px) {
    &__wrapper {
      grid-template-columns: 1fr;
    }

    &__border {
      display: block !important;
    }

    &__cell:nth-child(even) &__border {
      background-color: #f8b461;
    }

    &__cell:nth-child(odd) &__border {
      background-color: $primary;
    }
  }
}

.course-expand-btn {
  display: inline-block;
  margin-top: 10px;
}

.achievement {
  position: relative;

  &__iwrap {
    @include iwrap(150%);
    @include img-shine;
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(#fff, 0.9);
    background-clip: padding-box;
    border: 10px solid transparent;
    padding: 10px;
    opacity: 0;
    transition: 0.4s;

    &:hover {
      opacity: 1;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 12px;
  }

  &__desc {
    text-align: center;
  }

  &__link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.tags {
  position: relative;
  z-index: 1;

  &__float {
    width: 100%;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }

  &__item {
    display: block;
    color: $white;
    text-align: center;
    font-size: 12px;
    line-height: 25px;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform: skewX(-30deg);
      background-color: $secondary;
    }

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}

.topic {
  display: flex;
  align-items: center;
  padding: 18px 0 6px;

  &__detail {
    display: flex;
    margin-right: 50px;
  }

  &__detail-item {
    display: flex;
    width: 50px;
    height: 53px;
    flex-direction: column;
    text-align: center;
    font-size: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    color: $gray-800;
    padding-top: 2px;

    &:hover {
      color: $gray-800;
      border-color: $primary;
      text-decoration: none;
    }

    &:active {
      border-color: $primary;
      background-color: $primary;
      color: $white;
    }

    span:first-child {
      font-size: 18px;
    }
  }

  &__body {
    flex: 1;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    color: $primary;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
  }

  &__tag {
    display: inline-block;
    color: #2c558f;
    font-style: italic;
    font-size: 13px;
    line-height: 20px;
    padding: 0 8px;
    border-radius: 2px;
    background-color: #abc8f1;
    margin: 4px 10px 4px 0;

    &:hover {
      color: $white;
      background-color: $primary;
      text-decoration: none;
    }
  }

  &__latest {
    font-style: italic;

    a {
      font-weight: 700;
    }
  }

  @media (max-width: 575px) {
    flex-direction: column;

    &__title {
      font-size: 16px;
    }

    &__latest {
      font-size: 12px;
    }

    &__detail {
      width: 100%;
      order: 1;
      margin-right: 0;
      justify-content: space-between;
    }

    &__detail-item {
      flex-direction: row;
      align-items: baseline;
      width: auto;
      height: auto;
      font-size: 13px;
      padding: 2px 4px;

      span:first-child {
        font-size: 14px;
        margin-right: 6px;
      }
    }
  }
}

.forum-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #666;
  margin-top: 20px;
}

.forum-prev,
.forum-next {
  display: inline-flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #666;

  &:hover {
    color: $primary;
    text-decoration: none;
  }
}

.forum-page {
  margin-left: 10px;
  color: #999;
}

.panel-sort {
  border-color: $primary;
  color: $primary;
  background-color: transparent;
}

.s-card {
  @include shadow;
  background-color: $white;
  border-radius: 4px;
  padding: 20px;

  &__block {
    & + & {
      border-top: 1px solid $border-color;
      padding-top: 16px;
      margin-top: 16px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 4px;
    margin-bottom: 10px;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 50px;
      border-bottom: 2px solid $primary;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &__content {
    font-size: 13px;

    .finance-table {
      width: 100%;

      td {
        &:nth-child(2) {
          text-align: right;
          padding-left: 10px;
        }
      }
    }

    ul {
      padding-left: 17px;
    }
  }

  &__finance {
    margin-top: 16px;
  }

  &__finance-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__finance-text {
    text-align: center;
    font-size: 13px;
    margin-top: 4px;
  }
}

.school-detail-container {
  @media (min-width: 1200px) {
    max-width: 1500px;
  }
}
