.discover-banner {
  background: url('../images/discover-banner.jpg') center/cover no-repeat;
  color: $white;
  padding: 200px 0 95px;

  &__title {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    text-shadow: 2px 2px #000;
  }

  &__desc {
    text-align: center;
    font-size: 20px;
    margin-bottom: 12px;
  }

  &__form {
    max-width: 900px;
    margin: 0 auto;

    .form-control {
      height: 45px;
      padding: 0 30px;
      border: 0;
    }

    .input-group-text {
      background-color: $primary;
      color: $white;
      border: 0;
      font-size: 20px;
      font-weight: 700;
      padding: 0 20px;
    }
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 30px;
    }

    &__desc {
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    padding: 150px 0 90px;

    &__title {
      font-size: 24px;
      margin-bottom: 12px;
    }

    &__desc {
      margin-bottom: 20px;
    }

    &__form {
      .form-control {
        height: 35px;
        padding: 0 12px;
      }

      .input-group-text {
        font-size: 14px;
        padding: 0 12px;
      }
    }
  }
}

.filter {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  &__header {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: $white;
    background-color: $primary;
    border-radius: 4px 4px 0 0;
    padding: 8px;
    margin-bottom: 0;
  }

  &__body {
    border-top: 0;
    border-radius: 0 0 4px 4px;
    padding: 20px;

    .form-group {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.panel {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  &__header {
    background-color: $primary;
    border-radius: 4px 4px 0 0;
    align-items: center;
    padding: 5px 30px;
    color: $white;
  }

  &__logo {
    @include iwrap(100%);
    width: 55px;
    margin-right: 15px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-top: 3px;

    img {
      height: 16px;
      position: relative;
      top: -2px;
    }
  }

  &__body {
    padding-left: 46px;
    border-radius: 0 0 4px 4px;
  }

  &__more {
    display: inline-block;
    padding: 4px 8px 0;
    font-size: 30px;
    line-height: 1;

    &::before {
      content: '';
      display: inline-block;
      border: 8px solid transparent;
      border-top-color: $primary;
    }
  }

  @media (max-width: 1199px) {
    &__body {
      padding-left: 0;
    }
  }

  @media (max-width: 1199px) {
    &__header {
      padding: 5px 10px;
    }

    &__info {
      flex-direction: column;
    }
  }
}

.panel-2 {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  &__header {
    background-color: $primary;
    border-radius: 4px 4px 0 0;
    align-items: center;
    padding: 5px 30px;
    color: $white;
  }

  &__logo {
    @include iwrap(100%);
    width: 55px;
    margin-right: 15px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-top: 3px;

    img {
      height: 16px;
      position: relative;
      top: -2px;
    }
  }

  &__body {
    border-radius: 0 0 4px 4px;
    padding: 20px 20px 0;
  }

  &__more {
    display: inline-block;
    padding: 4px 8px 0;
    font-size: 30px;
    line-height: 1;

    &::before {
      content: '';
      display: inline-block;
      border: 8px solid transparent;
      border-top-color: $primary;
    }
  }

  @media (max-width: 1199px) {
    &__header {
      padding: 5px 10px;
    }

    &__info {
      flex-direction: column;
    }
  }
}
