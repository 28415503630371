.login {
  display: flex;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: url('../images/login-bg.jpg') center/cover no-repeat;

  &:before {
    content: '';
    display: block;
    width: 550px;
    height: 200vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(#fff, 0.2);
    transform-origin: top right;
    transform: rotate(44.5deg);
  }

  &__logo {
    display: flex;
    height: 106px;
    justify-content: center;
    align-items: center;
    background-color: rgba(#fff, 0.4);
    border-radius: 0 0 10px 10px;
    padding: 0 20px;
    position: absolute;
    z-index: 200;
    top: 0;
    left: 50px;

    img {
      height: 70px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__menu-btn {
    display: none;
  }

  @media (max-width: 991px) {
    &::before {
      display: none;
    }

    &__logo {
      height: 70px;
      padding: 0 10px;
      left: 15px;

      img {
        height: 50px;
      }
    }

    &__menu-btn {
      display: inline-flex;
      width: 40px;
      height: 35px;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(#fff, 0.9);
      border-radius: 4px;
      background: 0;
      padding: 0;
      font-size: 20px;
      color: rgba(#fff, 1);
      position: absolute;
      z-index: 200;
      top: 35px;
      right: 15px;
      transform: translateY(-50%);
      box-shadow: 0 1px 10px rgba(#000, 0.15);
    }
  }
}

.login-nav {
  &__header {
    display: none;
  }

  &__float {
    display: flex;
    align-items: center;
    position: absolute;
    top: 30px;
    right: 50px;
    z-index: 200;
  }

  &__btn {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 48px;
    border-radius: 25px;
    border: 1px solid $white;
    padding: 0 43px;
    color: $white;
    margin-left: 20px;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &__lang {
    font-size: 14px;
    font-weight: 700;

    .dropdown-toggle {
      display: inline-block;
      color: $white;
      padding: 8px 16px;

      &:hover {
        color: $white;
        text-decoration: none;
      }
    }

    .dropdown-menu {
      background-color: rgba($secondary, 0.6);
    }

    .dropdown-item {
      color: $white;

      img {
        height: 20px;
        position: relative;
        top: -1;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: rgba(#fff, 0.2);
      }
    }
  }

  @media (max-width: 991px) {
    width: 100vw;
    height: 100vh;
    overflow: auto;
    padding: 20px;
    background-color: $secondary;
    z-index: 300;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: 0.4s ease-in-out;

    &.show {
      transform: translateX(0);
    }

    &__float {
      position: static;
      flex-direction: column;
      align-items: flex-start;
    }

    &__lang {
      .dropdown-toggle {
        display: block;
        padding: 8px 0;
      }

      .dropdown-menu {
        width: 100%;
        margin: 0;
        transform: unset !important;
        position: static !important;
        border: 1px solid rgba(#fff, 0.6);
      }
    }

    &__btn {
      margin-top: 20px;
      margin-left: 0;
    }

    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      background-color: rgba(#fff, 0.2);
      margin: -20px -20px 20px;
      padding: 0 20px;
      text-align: center;
      color: $white;
      font-size: 12px;
      font-weight: 700;
      font-style: italic;
    }

    &__close {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 5px;
      right: 20px;
      border-radius: 50%;
      border: 1px solid rgba(#fff, 0.6);
      background: 0;
      color: $white;
    }
  }
}

.login-menu {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  position: absolute;
  z-index: 200;
  top: 160px;
  left: 50px;

  &__link {
    display: inline-block;
    padding: 10px 0;
    padding-left: 36px;
    position: relative;
    left: 0;
    color: $white;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    transition: 0.3s;

    &:hover {
      color: $white;
      text-decoration: none;
      left: 20px;
    }

    img {
      height: 18px;
      position: absolute;
      top: 10px;
      left: 0;
    }
  }

  @media (max-width: 991px) {
    position: static;

    &__item {
      margin-bottom: 10px;
    }

    &__link {
      font-size: 14px;
    }
  }
}

.login-card {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 15px;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;

  &__wrapper {
    width: 350px;
    max-width: 100%;
    padding: 30px 0;
    margin: auto;
  }

  &__inner {
    height: 264px;
    padding-right: 8px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 20px;

    &::-webkit-scrollbar {
      width: 2px;
      background: 0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: $secondary;
    }

    &::-webkit-scrollbar-track {
      width: 1px;
      background-color: rgba(#fff, 0.2);
    }
  }

  &__title {
    font-size: 21px;
    font-weight: 700;
    color: $white;
    text-align: center;
    text-shadow: 3px 5px 5px rgba(#000, 0.2);
    margin-bottom: 20px;
  }

  &__body {
    background-color: rgba($secondary, 0.5);
    border-radius: 30px;
    padding: 40px 25px 20px;
    color: $white;
  }

  &__btn {
    display: block;
    width: 100%;
    border: 1px solid rgba(#fff, 0.6);
    background: rgba($secondary, 0.6);
    color: $white;
    font-weight: 700;
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    padding: 7px;
    border-radius: 27px;
    box-shadow: 1px 2px 5px rgba(#000, 0.3);

    &:hover {
      background: rgba($secondary, 0.8);
    }
  }

  .form-group {
    margin-bottom: 20px;

    label {
      margin-bottom: 0;
    }

    .form-control {
      height: 30px;
      background: 0;
      border: 0;
      border-bottom: 1px solid rgba(#fff, 0.6);
      border-radius: 0;
      padding: 0;
      color: $white;
    }
  }

  .text-danger {
    color: $orange;
  }

  @media (max-width: 991px) {
    display: block;

    &__body {
      padding: 30px 20px 20px;
    }

    &__wrapper {
      padding: 50px 0 0;
    }
  }

  @media (max-width: 767px) and (orientation: landscape) {
    overflow: auto;
  }
}

.login-social {
  justify-content: space-around;

  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 1px solid $white;
    border-radius: 50%;
    padding: 0;
    color: $white;
    box-shadow: 1px 3px 5px rgba(#000, 0.3);

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}

.login-tabs {
  justify-content: space-between;
  margin-bottom: 28px;

  .nav-link {
    padding: 0;
    color: $white;
    font-weight: 700;

    &:hover {
      color: $white;
    }

    &.active {
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 100%;
        border-bottom: 1px solid $white;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}
