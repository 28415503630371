.school-banner {
  display: flex;
  flex-direction: column;
  padding: 120px 0 20px;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;

  &__bg {
    @include img-cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &__panel {
    width: 320px;
    padding: 10px;
    max-width: 100%;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      border: 3px solid $white;
      filter: drop-shadow(3px 3px 3px #000);
    }
  }

  &__content {
    padding: 20px;
    background-color: rgba(#384caf, 0.5);
    color: $white;
  }

  &__header {
    width: 170px;
    margin: -38px auto 20px;
    background-color: $white;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      border: 9px solid transparent;
      border-color: transparent #4c4c4e #4c4c4e transparent;
      position: absolute;
      z-index: -2;
      top: 0;
      right: 100%;
    }

    &::after {
      left: 100%;
      right: auto;
      border-color: transparent transparent #4c4c4e #4c4c4e;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 60px;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 35px;
      height: 20px;
      background: url('../images/icon-book-open.png') center/contain no-repeat;
      position: absolute;
      bottom: -50px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__subtitle {
    text-align: center;
    font-size: 24px;
    line-height: 1.2;
  }

  &__btn {
    display: inline-block;
    font-size: 16px;
    line-height: 37px;
    padding: 0 50px;
    border-radius: 4px;
    background-color: $primary;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  @media (max-width: 991px) {
    &__panel {
      margin: 0 auto;
    }
  }
}

.school-intro {
  padding-top: 50px;

  &__title {
    font-size: 36px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 16px;
  }
}

.school-tabs {
  &__nav {
    flex-direction: column;

    .nav-link {
      padding: 14px 20px;
      font-size: 16px;
      font-weight: 700;
      color: $gray-800;

      &:hover {
        color: $gray-800;
      }

      &.active {
        border: 1px solid $border-color;
        border-right: 0;
        position: relative;

        &::after {
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          z-index: 2;
          top: 0;
          right: -1px;
          border-right: 1px solid $white;
        }
      }
    }
  }

  &__content {
    padding: 24px;
    border: 1px solid $border-color;
  }

  @media (max-width: 991px) {
    &__nav {
      display: block;
      overflow: auto;
      white-space: nowrap;
      position: relative;
      z-index: 1;
      top: 1px;

      .nav-item {
        display: inline-block;
      }

      .nav-link {
        padding: 8px 16px;

        &.active {
          border-bottom: 0;
          border-right: 1px solid $border-color;

          &::after {
            height: auto;
            width: 100%;
            top: auto;
            bottom: 0;
            right: 0;
            border-right: 0;
            border-bottom: 1px solid $white;
          }
        }
      }
    }
  }
}

.feedback {
  padding: 20px;
  transition: 0.3s;

  &:hover {
    background-color: #f1f1f1;
  }

  &__text {
    text-align: justify;
    margin-bottom: 20px;
  }

  &__avatar {
    @include iwrap(100%);
    width: 60px;
    margin-right: 10px;
  }

  &__name {
    font-size: 14px;
    font-weight: 700;
    font-style: italic;
    color: $primary;
  }

  &__pos {
    font-style: italic;
  }
}

.school-aside {
  background-color: #01062c;
  border-right: 8px solid $primary;
  padding: 0 20px 20px;

  @media (max-width: 1199px) {
    margin-top: 150px;
  }
}

.school-info {
  color: $white;

  &__logo {
    display: block;
    width: 200px;
    margin: 0 auto -90px;
    border: 2px solid $primary;
    max-width: 100%;
    position: relative;
    z-index: 100;
    border-radius: 50%;
    transform: translateY(-50%);

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }

    &-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px;
      background-color: $white;
      border: 6px solid $primary;
      padding: 16px;
      border-radius: 50%;
      overflow: hidden;

      img {
        max-height: 100%;
        object-fit: contain;
        object-position: 50% 50%;
      }
    }
  }

  &__name {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    padding: 8px 0;
    margin-bottom: 0;
  }

  &__flag {
    height: 14px;
  }
}

.school-text {
  @include shadow;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  font-weight: 700;
  background-color: $white;
  margin-bottom: 20px;
}

.school-amount {
  color: $white;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: 16px 0;
}

.school-rating {
  color: $white;

  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
  }

  &__stars {
    color: $primary;
    font-size: 16px;
    letter-spacing: 10px;
    text-align: center;
    padding: 10px 0 5px;
  }

  &__small-stars {
    color: $primary;
    margin-right: 10px;
    letter-spacing: 8px;
    min-width: 95px;
    font-size: 12px;
  }
}

.school-gallery-container {
  position: relative;
  z-index: 2;
}

.school-gallery-inner {
  overflow: hidden;
  width: 100%;
  position: absolute;
  z-index: 2;
  bottom: 20px;
  left: 0;
}

.school-gallery {
  &__iwrap {
    @include iwrap(33%);
    border: 2px solid $white;
  }

  .swiper-container {
    overflow: visible;
  }
}

.school-location {
  &__label {
    margin-bottom: 10px;
  }

  &__map {
    height: 300px;
  }
}

.school-col-devider {
  &:not(:last-child) {
    border-right: 1px dashed $border-color;
  }

  @media (max-width: 767px) {
    &:not(:last-child) {
      border-right: 0;
      border-bottom: 1px dashed $border-color;
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
  }
}

.school-features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 10px 0;
  }

  &__label {
    font-size: 16px;
    font-weight: 700;
    margin-top: 15px;
  }

  @media (max-width: 575px) {
    &__item {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.school-about {
  &__more {
    display: none;
  }

  &.is-collapse {
    max-height: 210px;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
      background-image: linear-gradient(to top, #fff, #fff, transparent);
    }
  }

  &.is-collapse &__more {
    display: inline-block;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.school-scroll {
  border-bottom: 1px solid $border-color;
  margin-bottom: 8px;
  white-space: nowrap;

  .nav-item {
    flex-grow: 1;
  }

  .nav-link {
    text-align: center;
    font-size: 14px;
    line-height: 45px;
    text-transform: uppercase;
    font-weight: 700;
    color: $gray-800;
    padding: 0 16px;

    &:hover {
      color: $primary;
    }
  }

  @media (max-width: 1199px) {
    display: block;
    overflow: auto;

    .nav-item {
      display: inline-block;
    }
  }
}
