// style the checkbox
.checkbox-styled {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  padding-left: 30px;
  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  &__icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    position: absolute;
    top: 1px;
    left: 0;
    &::before {
      content: '\f00c';
      font-family: 'fontawesome';
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 13px;
      transform: scale(0);
    }
  }
  &:hover &__icon {
    border-color: $primary;
  }
  &__input:checked + &__icon {
    border-color: $primary;
    &::before {
      transform: scale(1);
      transition: transform 0.2s cubic-bezier(0.68, -0.75, 0.265, 1.75);
    }
  }
}

// style the radio
.radio-styled {
  display: block;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  padding-left: 30px;
  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  &__icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
    border: 1px solid #cccccc;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 0;
    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: scale(0) translate(-50%, -50%);
      background-color: $primary;
      border-radius: 50%;
    }
  }
  &:hover &__icon {
    border-color: $primary;
  }
  &__input:checked + &__icon {
    border-color: $primary;
    &::before {
      transform: scale(1) translate(-50%, -50%);
      transition: transform 0.2s cubic-bezier(0.68, -0.75, 0.265, 1.75);
    }
  }
}

// product or cart page quantity
.product-quantity {
  width: 110px;
  .form-control {
    text-align: center;
    font-size: 14px;
    &:focus {
      box-shadow: unset;
    }
  }
  .input-group-text {
    font-size: 9px;
    background-color: #fff;
  }
}

.switch {
  display: inline-flex;
  margin-bottom: 0;
  cursor: pointer;
  position: relative;

  &__input {
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50px;
    height: 24px;
    padding: 0 6px;
    border-radius: 12px;
    position: relative;
    background-color: $gray-600;
    z-index: 1;
    color: $white;
    transition: 0.3s;

    .fa {
      display: inline-block;
      padding: 0 2px;
      font-size: 13px;
    }
  }

  &__btn {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: $white;
    position: absolute;
    z-index: 2;
    top: 1px;
    left: 1px;
    transition: 0.3s;
  }

  &__input:checked ~ &__btn {
    left: 27px;
  }

  &__input:checked ~ &__icons {
    background-color: $primary;
  }

  &:focus &__btn {
    box-shadow: 0 1px 10px rgba($primary, 0.3);
  }
}

// file input
.file-input {
  display: block;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid $white;
  text-align: center;
  line-height: 30px;
  font-size: 13px;

  &__input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}

.chosen-container {
  .chosen-single {
    display: block;
    background: 0;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid rgba($white, 0.6);
    box-shadow: unset;
    font-size: 14px;
    line-height: 30px;
    padding: 0;
    height: auto;

    &.chosen-default {
      color: #999;

      &:hover {
        color: #999;
      }
    }

    .search-choice-close {
      background: 0;
      top: 0;

      &::before {
        content: '\f00d';
        font-family: 'fontawesome';
        color: $white;
        font-size: 14px;
        line-height: 30px;
      }
    }

    div {
      b {
        background: 0;

        &::after {
          content: '\f0d7';
          font-family: 'fontawesome';
          color: #fff;
        }
      }
    }
  }

  &.chosen-with-drop {
    border: 0;

    .chosen-single {
      background: 0;
      border: 0;
      box-shadow: unset;
      border-bottom: 1px solid rgba($white, 0.6);
    }

    .chosen-drop {
      position: static;
      background: 0;
      color: $white;
    }

    .chosen-search {
      position: relative;

      &::after {
        content: '\f002';
        font-family: 'fontawesome';
        position: absolute;
        font-size: 12px;
        top: 8px;
        right: 12px;
      }
    }

    .chosen-search-input {
      color: $white;
      background: 0 !important;
    }

    .chosen-results {
      color: $white;

      .active-result {
        padding: 8px 6px;
      }

      .active-result:hover,
      .active-result.result-selected {
        background: rgba($primary, 0.5);
      }
    }
  }
}
