.md-check {
  .modal-content {
    overflow: hidden;
  }

  .modal-header {
    padding: 15px 40px;
    background-color: $primary;
  }

  .modal-body {
    padding: 0;
  }

  .swiper-container {
    .swiper-pagination {
      display: flex;
      position: relative;
      z-index: 10;
      bottom: auto;
      left: auto;
      padding: 70px 70px 0;
    }

    .swiper-pagination-bullet {
      flex: 1;
      height: 7px;
      border-radius: 0;
      opacity: 1;
      margin: 0;
      background-color: $primary;
      position: relative;

      &:focus {
        outline: 0;
      }

      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $primary;
        box-shadow: 0 0 0 6px rgba($primary, 0.5);
        position: absolute;
        z-index: 2;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
      }

      &::after {
        content: 'Start';
        position: absolute;
        z-index: 3;
        top: -50px;
        right: 0;
        transform: translateX(50%);
        white-space: nowrap;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: $primary;
      }

      &:nth-child(2)::after {
        content: 'Step 1';
      }

      &:nth-child(3)::after {
        content: 'Step 2';
      }

      &:nth-child(4)::after {
        content: 'Finish';
      }

      &:first-child {
        flex: 0;
      }
    }

    .swiper-pagination-bullet-active ~ .swiper-pagination-bullet {
      background-color: $border-color;

      &::before {
        background-color: #cccccc;
        box-shadow: 0 0 0 6px $border-color;
      }
    }

    .swiper-slide {
      background: $white;
      padding: 30px 40px;
    }
  }

  .input-group {
    .input-group-text {
      padding: 6px 4px;
      min-width: 30px;
      text-align: center;
      justify-content: center;
      background-color: $white;
    }

    .input-group-prepend {
      .input-group-text {
        border-right: 0;
      }

      & + .form-control {
        border-left: 0;
        padding-left: 0;

        &::placeholder {
          color: #aaa;
        }
      }
    }

    .form-control:first-child {
      border-right: 0;
      padding-right: 0;
    }
  }

  &__close {
    border: 0;
    background: 0;
    font-size: 35px;
    color: $white;
    padding: 0;
    line-height: 35px;
  }

  &__clear {
    border: 0;
    background: 0;
    font-size: 20px;
    font-weight: 600;
    color: $white;
    padding: 0;
    line-height: 35px;
  }

  &__title {
    border-bottom: 1px solid $border-color;
    font-size: 18px;
    font-weight: 600;
    padding: 20px 0;
    margin-bottom: 20px;
  }

  &__btn {
    width: 240px;
    margin: 0 5px;

    &.btn-secondary {
      background-color: $gray-600;
      border-color: $gray-600;
    }
  }

  &__text {
    text-align: center;

    &--1 {
      font-size: 30px;
      font-weight: 600;
    }

    &--2 {
      font-size: 18px;
      font-weight: 600;
    }

    &--3 {
      font-size: 14px;
    }
  }

  &__range {
    padding: 12px 15px;
    border-radius: 4px;
    background-color: #f9f9f9;
  }

  &__range-wrap {
    padding: 0 24px 24px;
    position: relative;
  }

  &__range-start {
    position: absolute;
    bottom: 0;
    left: 24px;
    text-align: center;
    transform: translateX(-50%);
  }

  &__range-end {
    position: absolute;
    bottom: 0;
    right: 24px;
    text-align: center;
    transform: translateX(50%);
  }

  @media (max-width: 767px) {
    .swiper-container {
      .swiper-slide {
        padding: 30px 16px;
      }

      .swiper-pagination {
        padding: 60px 30px 0;
      }

      .swiper-pagination-bullet {
        height: 3px;

        &::before {
          width: 10px;
          height: 10px;
        }

        &::after {
          font-size: 14px;
          top: -35px;
        }
      }
    }

    .modal-header {
      padding: 16px;
    }

    &__close {
      font-size: 24px;
      line-height: 24px;
    }

    &__clear {
      font-size: 16px;
      line-height: 24px;
    }

    &__text {
      &--1 {
        font-size: 24px;
      }
    }
  }
}
