.footer {
  background-color: $secondary;
  padding-top: 15px;

  &__logo {
    display: inline-flex;
    margin-top: 24px;
    margin-bottom: 20px;

    img {
      height: 50px;
      object-fit: contain;
      object-position: 0 0;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    color: $primary;
    margin-bottom: 8px;
  }

  &__end {
    border-top: 1px solid rgba(#fff, 0.2);
    padding: 20px 0;
  }

  &__copyright {
    color: $white;
    text-align: center;
  }

  &__btn {
    display: inline-block;
    border: 1px solid $white;
    border-radius: 4px;
    font-size: 14px;
    line-height: 38px;
    color: $white;
    padding: 0 38px;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}

.f-contact {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  color: $white;

  &__item {
    position: relative;
    padding-left: 30px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    .fa {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      font-size: 16px;
    }

    a,
    a:hover {
      color: $white;
    }
  }
}

.f-social {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  margin-top: 25px;
  padding-left: 0;

  &__item {
    margin-bottom: 10px;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid $white;
    border-radius: 50%;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}

.f-nav {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(#fff, 0.2);
    }
  }

  &__link {
    display: block;
    color: $white;
    padding: 9px 0 9px 18px;
    position: relative;
    transition: 0.3s;

    &::before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      background: url('../images/icon-list-style-square.png') center/contain
        no-repeat;
      position: absolute;
      top: 16px;
      left: 0;
      transition: 0.3s;
    }

    &:hover {
      color: $white;
      text-decoration: none;
      padding-left: 24px;
    }

    &:hover::before {
      left: 6px;
    }
  }
}

.term-nav {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      &::after {
        content: '|';
        display: inline-block;
        padding: 0 6px;
        color: $white;
      }
    }
  }

  &__link {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}
