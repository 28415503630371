.difference {
  &__iwrap {
    display: flex;
    width: 105px;
    height: 105px;
    justify-content: center;
    align-items: center;
    border: 3px solid $orange;
    border-radius: 50%;
    margin: 0 auto;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-top: 4px;
  }

  &__desc {
    text-align: center;
  }
}

.feature {
  overflow: hidden;
  background: url('../images/feature-bg.jpg') center/cover no-repeat;

  &__inner {
    position: relative;
  }

  &__girl {
    max-height: 110%;
    object-fit: contain;
    object-position: 0 100%;
    position: absolute;
    bottom: 0;
    left: -30px;
  }

  &__item {
    color: $white;
    margin-bottom: 90px;
  }

  &__icon {
    text-align: center;

    img {
      height: 65px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  &__desc {
    text-align: center;
  }

  @media (max-width: 1199px) {
    &__girl {
      display: none;
    }
  }
}

.usage {
  &__step {
    flex-direction: column;
    min-height: 100%;
    border-right: 1px solid $border-color;

    .nav-item {
    }

    .nav-link {
      background-color: $white;
      color: $gray-800;
      border: 1px solid transparent;

      &:hover {
        color: $gray-800;
        text-decoration: none;
      }

      &.active {
        border-color: $border-color;
        border-right: 0;
        position: relative;

        &::after {
          content: '';
          display: block;
          height: 100%;
          border-right: 1px solid $white;
          position: absolute;
          top: 0;
          left: 100%;
        }
      }
    }
  }

  &__label {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
  }

  &__banner {
    border-radius: 20px;
  }
}

.faq {
  background-color: #f9f9f9;

  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 -15px;
    padding-left: 0;
  }

  &__item {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
    margin-bottom: 5px;
  }

  &__content {
    background-color: $white;
    padding: 15px 15px 15px 68px;
    position: relative;
  }

  &__icon {
    position: absolute;
    top: 24px;
    left: 15px;
  }

  &__header {
    position: relative;
    padding-right: 50px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
  }

  &__toggle {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: #131438;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: 0.3s;
    cursor: pointer;

    &.active {
      transform: translateY(-50%) rotate(90deg);
      background-color: $primary;
    }
  }

  &__body {
    display: none;
    border-top: 2px dashed $gray-800;
    padding-top: 20px;
    margin-top: 20px;
  }

  @media (max-width: 991px) {
    &__item {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
