.dropdown-menu {
  background-clip: border-box;
  border: 0;
  margin-top: 0 !important;
}
.dropdown-item {
  font-size: 0.875rem;
  padding: 0.5rem 1.25rem;
  &:active {
    background-color: #333;
  }
}
.dropdown-title {
  display: flex;
  align-items: center;
  position: relative;
}
.dropdown-link {
  flex: 1;
}
.dropdown-caret,
.dropdown-caret-light {
  display: flex;
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: .3s;
  background-color: rgba(0,0,0,0.05);
  color: inherit;
  margin-right: .5rem;
  &-float {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
.dropdown-caret-light {
  background-color: rgba(255,255,255,0.3);
}
@mixin dropdownExpand {
  box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23);
}
@each $key, $val in $grid-breakpoints {
  @media (min-width: #{$val}) {
    .navbar-expand-#{$key} {
      .dropdown-caret {
        display: none;
      }
      .dropdown:hover > .dropdown-menu {
        display: block;
        animation: fadeInUp .3s ease-out;
      }
      .dropdown-menu {
        @include dropdownExpand;
      }
    }
  }
}

// animation
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15%, 0);
    transform: translate3d(0, 15%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
