.blog-banner {
  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;

    .fa {
      display: flex;
      width: 56px;
      height: 56px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid $white;
    }
  }

  @media (max-width: 767px) {
    .carousel-control-prev,
    .carousel-control-next {
      .fa {
        width: 30px;
        height: 30px;
        font-size: 10px;
      }
    }
  }
}

.blog-search {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid $primary;
  border-radius: 4px;
  background-color: $primary;
  margin: 30px 0;

  &__input {
    flex: 0 0 280px;
    max-width: 280px;
    height: 60px;
    line-height: 60px;
    border: 0;
    border-radius: 4px 0 0 4px;
    background-color: $white;
    padding: 0 12px;
    text-align: center;
    font-size: 16px;
    font-style: italic;
  }

  &__quick {
    display: block;
    flex: 1;
    background-color: $primary;
    color: $white;
    font-size: 16px;
    line-height: 60px;
    padding: 0 16px;
    text-align: center;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken($primary, 10%);
    }
  }

  @media (max-width: 1199px) {
    &__input {
      flex: 0 0 100%;
      max-width: 100%;
      height: 40px;
      font-size: 14px;
      border-radius: 4px 4px 0 0;
    }

    &__quick {
      flex: 0 0 50%;
      max-width: 50%;
      font-size: 14px;
      line-height: 40px;
    }
  }
}

.blog-section {
  &__header {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 30px;

    &::after {
      content: '';
      display: block;
      height: 42px;
      flex: 1;
      margin-left: 16px;
      background-image: url('../images/blog-header-bg.png');
    }
  }

  &__title {
    font-size: 18px;
    padding: 0 26px;
    line-height: 58px;
    border-radius: 4px;
    background-color: $primary;
    color: $white;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

.blog-video-slider {
  width: calc(100% + 30px);
  margin: -15px -15px 0;
  padding-bottom: 20px;

  .swiper-slide {
    padding: 15px;
  }

  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__prev,
  &__next {
    display: flex;
    width: 26px;
    height: 26px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid $primary;
    color: $primary;
    margin: 0 14px;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.blog-video {
  &__iwrap {
    display: block;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    @include shadow;

    &::before {
      content: '';
      display: block;
      padding-bottom: 73%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__play-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(#131438, 0.45);
    transition: 0.3s;
    z-index: 10;
    top: 0;
    left: 0;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: rgba(#131438, 0.65);
    }

    .fa {
      display: flex;
      width: 75px;
      height: 75px;
      justify-content: center;
      align-items: center;
      border: 2px solid rgba(#fff, 0.8);
      border-radius: 50%;
      font-size: 30px;
      padding-left: 4px;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    padding: 0 10px;
    margin: 20px 0 14px;
  }

  &__info {
    padding: 0 10px;
  }
}

.blog-news {
  align-items: stretch;
  background-color: #e9e9e9;
  border-radius: 4px;
  position: relative;
  transition: 0.3s;

  &:hover {
    background-color: #ccc;
  }

  &__link {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__iwrap {
    @include iwrap(90%);
    width: 120px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(#000, 0.1);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 16px 25px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  &__info {
    margin-top: auto;
    color: #666;
  }
}

.blog-news-2 {
  height: 100%;
  position: relative;

  &__iwrap {
    display: flex;
    height: 100%;
    margin-left: 95px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      border-radius: 4px;
    }
  }

  &__content {
    width: 380px;
    border-radius: 4px;
    padding: 66px 30px 25px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: rgba(#fff, 0.9);
    box-shadow: -5px 10px 20px rgba(#000, 0.3);
  }

  &__label {
    display: block;
    width: 30px;
    height: 30px;
    padding-top: 7px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    background-color: $primary;
    color: $white;
    position: absolute;
    top: 0;
    left: 30px;

    &::before,
    &::after {
      content: '';
      border-width: 5px 8px;
      border-style: solid;
      border-color: $primary transparent transparent $primary;
      position: absolute;
      top: 100%;
      left: 0;
    }

    &::after {
      left: auto;
      right: 0;
      transform: rotateY(180deg);
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    color: $primary;
    margin-bottom: 10px;
  }

  &__info {
    color: #999;
    margin-bottom: 20px;
  }

  &__desc {
    @include max-line(4);
    margin-bottom: 20px;
  }

  &__tags {
    display: block;
    margin-bottom: 24px;
  }

  &__tag {
    display: inline-block;
    padding: 0 12px;
    margin-bottom: 10px;
    font-size: 14px;
    font-style: italic;
    line-height: 25px;
    color: $gray-800;
    background-color: #c8dcfa;
    border-radius: 4px;
    margin-right: 15px;
    transition: 0.3s;

    &:hover {
      color: $white;
      background-color: $primary;
      text-decoration: none;
    }
  }

  &__btn {
    @include btn-fill;
    display: inline-flex;
    font-size: 14px;
    line-height: 32px;
    padding: 0 20px;
    border: 2px solid $primary;
    border-radius: 4px;
    color: $primary;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &::before {
      background-color: $primary;
    }
  }

  @media (max-width: 991px) {
    height: auto;
    padding: 60px 60px 0;
    margin-bottom: 30px;

    &__iwrap {
      height: auto;
      margin-left: 0;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 60px;
    }

    &__content {
      position: static;
      max-width: 100%;
      margin: 0 auto;
      transform: translateY(0);
    }
  }

  @media (max-width: 575px) {
    padding: 20px;

    &__iwrap {
      bottom: 0;
    }

    &__content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.blog-news-slider {
  padding: 35px 0;
  position: relative;
  z-index: 1;

  &__iwrap {
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 200px;
    border-radius: 4px;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $black;
      opacity: 0.2;
      z-index: 1;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__content {
    width: 320px;
    background-color: $white;
    border-radius: 4px;
    padding: 20px 24px 20px;
    margin-left: auto;
  }

  &__nav {
    display: flex;
    margin-top: 20px;
  }

  &__prev,
  &__next {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    color: #666;
    transition: 0.3s;
    border-radius: 4px;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    &:hover {
      background-color: rgba(#000, 0.1);
    }
  }

  @media (max-width: 575px) {
    padding: 30px 20px;

    &__content {
      width: 100%;
      padding-top: 40px;
    }

    &__iwrap {
      right: 0;
    }
  }
}

.blog-news-showmore {
  display: block;
  text-align: center;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  font-size: 16px;
  line-height: 60px;
  font-weight: 700;
  color: $primary;
  transition: 0.3s;

  &:hover {
    color: $primary;
    text-decoration: none;
    background-color: rgba(#000, 0.08);
  }
}

.forum-filter {
  display: flex;
  border: 1px solid #363636;
  border-radius: 4px;

  &__group {
    display: flex;
    flex: 1;
    border-right: 1px solid #363636;
  }

  &__item {
    display: block;
    flex: 1;
    text-align: center;
    font-size: 14px;
    line-height: 50px;
    padding: 0 16px;
    color: $black;

    &:not(:last-child) {
      border-right: 1px solid #363636;
    }

    &:hover,
    &.active {
      text-decoration: none;
      background-color: #abc8f1;
      color: $white;
    }
  }

  &__dropdown {
    flex: 0 0 54px;
    max-width: 54px;

    .dropdown-menu {
      @include shadow;
    }

    .dropdown-item {
      &:not(:last-child) {
        border-bottom: 1px dashed $border-color;
      }
    }
  }

  &__dropdown-toggle {
    display: block;
    line-height: 50px;
    text-align: center;
    color: $black;
    font-size: 22px;

    &:hover {
      color: $black;
      text-decoration: none;
    }
  }

  @media (max-width: 1199px) {
    &__group {
      display: block;
      white-space: nowrap;
      overflow: auto;

      &::after {
        content: '';
        display: table;
        clear: both;
      }
    }

    &__item {
      display: inline-block;
    }
  }
}

.forum-tabs {
  box-shadow: inset 0 0 0 1px $border-color;
  border-radius: 4px;
  margin-bottom: 30px;

  .nav-item {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .nav-link {
    font-size: 15px;
    font-weight: 700;
    color: $gray-800;
    padding: 0;
    line-height: 56px;
    text-align: center;

    &.active {
      background-color: $primary;
      color: $white;
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-width: 14px 6px;
        border-style: solid;
        border-color: $primary transparent transparent transparent;
      }
    }
  }

  .nav-item:first-child .nav-link {
    border-radius: 4px 0 0 4px;
  }

  .nav-item:last-child .nav-link {
    border-radius: 0 4px 4px 0;
  }
}

.blog-post {
  &__iwrap {
    @include iwrap(80%);
    @include hover-zoom;
    width: 110px;
    margin-right: 20px;
  }

  &__title {
    @include max-line(3);
    font-size: 14px;
    font-weight: 700;

    a:hover {
      color: $primary;
    }
  }

  &__stars {
    letter-spacing: 3px;
    color: $primary;
  }
}

.blog-detail {
  &-title {
    font-size: 28px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
}

.blog-aside {
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &__title {
    font-size: 15px;
    line-height: 24px;
    font-weight: 700;
    border-radius: 4px;
    color: $white;
    background-color: $primary;
    padding: 16px;
    text-align: center;
  }
}

.blog-menu {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    border-bottom: 1px solid $border-color;
  }

  &__link {
    display: block;
    font-size: 14px;
    color: $gray-800;
    padding: 10px 20px;
    transition: 0.3s;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border: 2px solid #ccc;
      border-radius: 50%;
      position: absolute;
      top: 18px;
      left: 2px;
    }

    &:hover {
      color: $primary;
      text-decoration: none;
      background-color: $light;
    }
  }
}
