.card {
  border: 0;
  @include shadow;

  & > *:first-child {
    border-radius: 10px 10px 0 0;
  }

  & > *:last-child {
    border-radius: 10px 10px 0 0;
  }

  &-header {
    background-color: $primary;
    color: $white;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    border-bottom: 0;
  }

  &-title {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 4px;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 60px;
      border-bottom: 2px solid $primary;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &-subtitle {
    font-size: 16px;
    padding-bottom: 14px;
    margin-bottom: 16px;
    border-bottom: 1px solid $border-color;
  }

  &-list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  &-list-item {
    &::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      position: relative;
      top: -2px;
      background: url('../images/icon-list-style-square-orange.png')
        center/contain no-repeat;
      margin-right: 6px;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .table {
    border: 0;

    th:last-child,
    td:last-child {
      border-right: 0;
    }

    th:first-child,
    td:first-child {
      border-left: 0;
    }

    th {
      border-top: 0;
    }

    tr:last-child td {
      border-bottom: 0;
    }
  }
}
