.b-footer {
  background-color: $b-dark;
  padding: 50px 0 0;

  &__title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: $b-brown;
    margin-bottom: 10px;
  }

  &__copyright {
    color: $b-brown;
    border-top: 1px solid $b-brown;
    padding: 15px 0;
    text-align: center;
  }

  &__logo {
    display: inline-flex;
    margin-bottom: 30px;

    img {
      height: 60px;
    }
  }
}
