@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700,900&subset=vietnamese');
@font-face {
  font-family: 'UTMAvo';
  src: url('../fonts/UTMAvo.eot');
  src: url('../fonts/UTMAvo.eot?#iefix') format('embedded-opentype'),
    url('../fonts/UTMAvo.woff') format('woff'),
    url('../fonts/UTMAvo.ttf') format('truetype'),
    url('../fonts/UTMAvo.svg#svgFontName') format('svg');
  font-weight: 400;
}

$opensans: 'Open Sans', sans-serif;
