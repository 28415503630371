.event-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 50px;
  padding: 0 36px;
  background-color: #277ffd;
  color: $white;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  min-width: 200px;

  &:hover {
    color: $white;
    text-decoration: none;
  }
}

.event-list {
  list-style: none;
  margin-bottom: 16px;
  padding-left: 0;

  &__item {
    margin-bottom: 20px;
  }
}

.event-exhibition {
  padding: 50px 0 60px;

  &__title {
    font-size: 35px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }

  &__desc {
    text-align: center;
    margin-bottom: 40px;
  }
}

.event-upcoming {
  padding: 35px 0 60px;

  &__title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 16px;
    text-align: center;
  }

  &__item {
    position: relative;
  }

  &__link {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
  }

  &__iwrap {
    @include iwrap(130%);
  }

  &__body {
    background-color: #141414;
    color: $white;
    padding: 15px;
  }

  &__subtitle {
    font-size: 16px;
    text-align: center;
    background-color: #141414;
  }

  &__info {
    font-size: 12px;
    font-style: italic;
    text-align: center;
    margin: 4px 0 8px;
  }

  &__btn {
    display: inline-block;
    background-color: $white;
    text-transform: uppercase;
    font-size: 12px;
    color: #333;
    padding: 0 30px;
    line-height: 30px;

    &:hover {
      color: #333;
      text-decoration: none;
    }
  }
}

.event-info {
  padding-top: 30px;

  &__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__header {
    display: flex;
    border-bottom: 1px solid $border-color;
    align-items: center;
    padding-bottom: 16px;
  }

  &__list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  &__list-item {
    border-bottom: 1px solid $border-color;
    padding: 40px 0;
  }
}

.event {
  align-items: baseline;

  &__date {
    font-size: 16px;
    margin-right: 30px;

    span:first-child {
      font-size: 48px;
      margin-right: 10px;
    }
  }

  &__header {
    display: flex;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  &__btn {
    @include btn-fill;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 27px;
    line-height: 43px;
    border: 1px solid #333;
    border-radius: 2px;
    color: #333;

    &:hover {
      color: #fff;
      text-decoration: none;
    }

    &::before {
      background-color: #333;
    }
  }

  &__btns {
    margin-left: 20px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;

    &__date {
      order: -2;
    }

    &__btns {
      order: -1;
      margin: 16px 0;
    }
  }
}

.event-form {
  padding: 45px 0 60px;

  &__title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
  }

  .input-group {
    .form-control {
      border: 0;
      border-bottom: 1px solid $border-color;
      border-radius: 0;
      font-size: 16px;
    }

    .input-group-text {
      background: 0;
      border: 0;
      border-bottom: 1px solid $border-color;
      border-radius: 0;
      font-size: 24px;
    }
  }
}

.event-posts {
  padding: 36px 0 50px;

  &__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}

.event-post {
  align-items: stretch;

  &__iwrap {
    @include iwrap(56.25%);
    width: 50%;
  }

  &__body {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 40px 40px 0;
  }

  &__header {
    margin-bottom: 24px;
  }

  &__avatar {
    display: flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    background-color: #0097a7;
    color: $white;
    border-radius: 50%;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 12px;

    a {
      color: inherit;
    }
  }

  &__desc {
    @include max-line(4);
    margin-bottom: 20px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $border-color;
    padding: 10px 0;
    margin-top: auto;
    height: 55px;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;

    &__iwrap {
      width: 100%;
    }
  }
}

.event-slider {
  padding: 50px 0;
  position: relative;

  &__prev,
  &__next {
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    z-index: 10;
    font-size: 35px;
    margin-top: -25px;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: -20px;
  }

  &__iwrap {
    @include iwrap(56.25%);
  }

  &__overlay {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s;

    &:hover {
      background-color: rgba(#000, 0.3);
    }

    img {
      height: 30px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin: 12px 0 6px;
  }

  &__info {
    font-size: 12px;
    color: #999;
    text-align: center;
  }

  @media (max-width: 575px) {
    &__prev {
      left: -13px;
    }

    &__next {
      right: -13px;
    }
  }
}
