.page-link {
  border-radius: 0.25rem !important;
  color: $gray-800;
  padding: 8px 16px;
  box-shadow: 0 1px 3px rgba(#000, 0.3);
  border: 0;
}
.page-item {
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
  &.active .page-link {
    background-color: $primary;
    border-color: $primary;
  }
}
